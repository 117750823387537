import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import { BaseFieldComponent } from '../base-field.component';
import { AutocompleteOption, AutocompleteQuestion } from '../../models/form-elements/autocomplete';

export interface AutocompleteInputValue {
  text: string;
  value?: string;
}
@Component({
  selector: 'kin-form-autocomplete',
  templateUrl: './autocomplete.component.html',
})
export class FormAutocompleteComponent
  extends BaseFieldComponent<AutocompleteQuestion>
  implements DoCheck
{
  @Input() allowCustom: boolean;

  @Output() onFocus = new EventEmitter<Event>();

  suggestionList: AutocompleteOption[] = [];

  currentSuggestionIndex = -1;

  sansSuggestionInputText: string;

  private _fieldDataOptions: AutocompleteOption[];

  private _value: AutocompleteInputValue;

  ngDoCheck() {
    if (this.fieldData?.options !== this._fieldDataOptions && this.form?.get(this.fieldData.name)) {
      this._fieldDataOptions = this.fieldData.options;
      this.processSuggestionList();
    }
  }

  handleEscape() {
    this.inputValue = { text: this.sansSuggestionInputText };
    this.clearSuggestionList();
  }

  handleEnter(event: Event): void {
    event.preventDefault();
    if (this.currentSuggestionIndex === -1 && this.suggestionList?.length > 0) {
      this.currentSuggestionIndex = 0;
      this.inputValue = { text: this.suggestionList[this.currentSuggestionIndex]?.text };
    } else {
      this.selectSuggestion(this.suggestionList[this.currentSuggestionIndex]);
    }
  }

  handleFocus(e: Event): void {
    if (this.inputValue?.value === undefined) {
      this.processSuggestionList();
      this.onFocus.emit(e);
    }
  }

  handleBlur(): void {
    const suggestion = this.suggestionList[this.currentSuggestionIndex];
    if (suggestion) {
      this.selectSuggestion(suggestion);
    }
    this.clearSuggestionList();
  }

  handleInput(e: Event): void {
    this.currentSuggestionIndex = -1;
    this.inputValue = { text: (e.target as HTMLInputElement).value, value: undefined };
    this.sansSuggestionInputText = this.inputValue?.text;
    this.processSuggestionList();
  }

  moveToNextSuggestion(e: Event) {
    if (this.isSuggestionListActive) {
      e.preventDefault();
      this.currentSuggestionIndex += 1;
      if (this.currentSuggestionIndex > this.suggestionList.length - 1) {
        this.currentSuggestionIndex = 0;
      }
      const { text } = this.suggestionList[this.currentSuggestionIndex];
      this.inputValue = { text };
    }
  }

  moveToPreviousSuggestion(e: Event): void {
    if (this.isSuggestionListActive) {
      e.preventDefault();
      this.currentSuggestionIndex -= 1;
      if (this.currentSuggestionIndex < 0) {
        this.currentSuggestionIndex = this.suggestionList.length - 1;
      }
      const { text } = this.suggestionList[this.currentSuggestionIndex];
      this.inputValue = { text };
    }
  }

  handleKeydown(e: KeyboardEvent) {
    if (e.code !== 'Escape' && !this.isSuggestionListActive) {
      this.processSuggestionList();
    }
  }

  selectSuggestion(suggestion: AutocompleteOption): void {
    this.inputValue = { text: suggestion.text, value: suggestion.value ?? null };
    this.sansSuggestionInputText = this.inputValue.text;
    this.clearSuggestionList();
  }

  processSuggestionList(): void {
    if (this.sansSuggestionInputText && this.fieldData.options) {
      const inputTextLower = this.sansSuggestionInputText.toLowerCase();
      this.suggestionList = this.fieldData.options.filter((item) => {
        return item.text.toLowerCase().includes(inputTextLower);
      });
      this.suggestionList.sort((a, b) => {
        const aValue = a.priority ? 1 : 0;
        const bValue = b.priority ? 1 : 0;
        return bValue - aValue; // priority items go to the top
      });
      this.suggestionList = this.suggestionList.slice(0, 5);
      if (this.allowCustom && this.suggestionList.length === 0) {
        this.suggestionList.push(this.inputValue as AutocompleteOption);
      }
    } else {
      this.clearSuggestionList();
    }
  }

  clearSuggestionList(): void {
    this.suggestionList = [];
    this.currentSuggestionIndex = -1;
  }

  set inputValue(newValue: AutocompleteInputValue) {
    this._value = newValue;
    if (newValue.text == null) {
      this.form?.get(this.fieldData.name).patchValue(null);
    } else {
      this.form?.get(this.fieldData.name).patchValue(newValue);
    }
  }

  get inputValue(): AutocompleteInputValue {
    return this._value;
  }

  get isSuggestionListActive(): boolean {
    return this.suggestionList?.length > 0 && this.inputValue?.value === undefined;
  }
}
