<form #formElement [action]="" [method]="" [formGroup]="form" (ngSubmit)="fireSubmitEvent()" [ngClass]="layout">
  <div *ngFor="let field of fields; let i = index; trackBy: trackByFn" [ngClass]="field.classes">
    <kin-form-field [readonly]="options.readonly" [fieldData]="$any(field)" *ngIf="isFormField(field)" [form]="form"
      (autoSubmitRequest)="fireSubmitEvent()">
    </kin-form-field>
    <kin-page-element [elementData]="$any(field)" *ngIf="!isFormField(field)"></kin-page-element>
  </div>
  <button class="btn grid__submit" *ngIf="!options.removeSubmit" [disabled]="!form.valid || disableSubmit">{{
    options.buttonText || 'Submit' }}
  </button>
</form>
