import { Component, Input } from '@angular/core';
import { RadioQuestion } from '../../models/form-elements/radio';
import { BaseFieldComponent } from '../base-field.component';

@Component({
  selector: 'kin-form-radio',
  templateUrl: './radio.component.html',
})
export class FormRadioComponent extends BaseFieldComponent<RadioQuestion> {
  @Input() hasImages: boolean;

  onChange(e: Event, value: string) {
    this.formService.emitCallerElementId(this.fieldData.value);
    this.form.get(this.fieldData.name).setValue(value);
  }

  get gridSize() {
    return `h-grid h-grid--max-${this.numberOfImages < 4 ? this.numberOfImages : 4}`;
  }

  get numberOfImages() {
    return this.fieldData.options.filter((obj) => typeof obj.image !== 'undefined').length;
  }
}
