import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { RequestService } from './request.service';
import { SessionStorageService } from './session-storage.service';
import { LocalStorageService } from './local-storage.service';

/**
 * This service is a wrapper around LocalStorageService and
 * SessionStorageService that provides the Auth context.
 */

interface SiteConfig {
  backable: object;
  progress_bar: any[];
}

/**
 * Interface to match the structure of a JWT payload
 * @see https://jwt.io/
 */
interface JwtPayload {
  sub: string;
  name: string;
  exp: number;
}

@Injectable()
export class AuthService {
  private configSubject: BehaviorSubject<SiteConfig> = new BehaviorSubject<SiteConfig>(null);

  public configSubscription = this.configSubject.asObservable();

  constructor(
    private requestService: RequestService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService
  ) {}

  saveToken(token: string): void {
    this.localStorageService.save('token', token);
  }

  retrieveToken(): string | null {
    const token = this.localStorageService.retrieve('token');

    if (this.isValidToken(token)) {
      return token;
    }

    this.removeToken();
    return null;
  }

  removeToken(): void {
    return this.localStorageService.remove('token');
  }

  getConfig(): void {
    const configStorageKey = 'config';
    const storedConfig: string = this.sessionStorageService.retrieve(configStorageKey);
    if (storedConfig) {
      this.configSubject.next(JSON.parse(storedConfig));
    } else {
      this.requestService
        .get<any>(`${environment.baseUrl + environment.apiPrefix}/configs`)
        .toPromise()
        .then((data) => {
          this.sessionStorageService.save(configStorageKey, JSON.stringify(data));
          this.configSubject.next(data);
        });
    }
  }

  /**
   * Checks to see if the token exists within localStorage
   * @returns true if token found and is valid, false if not.
   */
  private isValidToken(jwtString: string | null): boolean {
    if (jwtString) {
      const jwtPayload = this.parseJwtPayload(jwtString);
      return !!jwtPayload && !this.isTokenExpired(jwtPayload);
    }
    return false;
  }

  /**
   * Splits out the payload from the JWT and decodes it
   * @returns the decoded JwtPayload object
   * null === malformed jwt string
   */
  private parseJwtPayload(jwtString: string): JwtPayload | null {
    const splitToken = jwtString.split('.')[1];
    if (splitToken) {
      const jwtPayload = atob(splitToken); // decode payload of token
      return JSON.parse(jwtPayload); // convert payload into an Object
    }
    return null;
  }

  /**
   * Checks if payload is expired
   * @returns false if expiration is less than or equal to current time, else true
   */
  private isTokenExpired(jwtPayload: JwtPayload): boolean {
    return jwtPayload.exp <= Date.now() / 1000;
  }
}
