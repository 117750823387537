import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

/**
 * @desc Click Outside Directive
 *
 * @example <div (ngkClickOutside)="isTomato = true">
 *
 * @example <div (ngkClickOutside)="tomatoify()">
 *
 * @fires {Event}
 *
 * * */
@Directive({
  selector: '[ngkClickOutside]',
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  unlistener!: () => void;

  /**
   * This is a custom directive that allows unique functionality to be
   * added within a template that is triggered when clicking anywhere outside
   * of the html element or angular component where the ngkClickOutside is referenced
   * This should not be used a bunch of places throughout the same form or page
   * because it can lead to adding to many listeners
   */
  @Output() ngkClickOutside = new EventEmitter<MouseEvent>();

  constructor(private elementRef: ElementRef, private renderer2: Renderer2) {}

  ngOnInit(): void {
    this.unlistener = this.renderer2.listen('document', 'click', (event: MouseEvent) => {
      const targetElementPath = event.composedPath();

      if (targetElementPath.length === 0) {
        return;
      }

      // checks to see if click came from inside the element with ngkClickOutside
      const clickedInside = targetElementPath.find((e) => e === this.elementRef.nativeElement);
      if (!clickedInside) {
        // if it came from without, emit event to trigger desired behavior in template
        this.ngkClickOutside.emit(event);
      }
    });
  }

  ngOnDestroy(): void {
    this.unlistener();
  }
}
