<label class="input-textbox" [for]="fieldData.id" [formGroup]="form">
  <select 
    class="input-textbox--select"
    [name]="fieldData.name"
    [attr.aria-describedby]="describedBy"
    [id]="fieldData.id" 
    [required]="fieldData.required"
    [formControlName]="fieldData.name">
    <ng-container *ngFor="let option of fieldData.options; trackBy: trackByValue">
      <option [value]="option.value">{{option.text}}</option>
    </ng-container>
  </select>
  <span class="input-textbox--icon"></span>
  <span class="input-textbox--label">
    {{fieldData.label}}
    <kin-form-tooltip
      *ngIf="!!fieldData.helpText && fieldData.helpText.type === 'tooltip'"
      [id]="fieldData.value + '__tooltip'"
      [helpText]="fieldData.helpText"
      [form]="form"
      class="tooltip__container"
    ></kin-form-tooltip>
  </span>
</label>