import { ChangeDetectorRef, Component, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Message, MessageService } from '../../services/message.service';

@Component({
  selector: 'kin-message',
  templateUrl: './message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements OnDestroy {
  private unSubAll$ = new Subject<void>();

  messages: Set<Message> = new Set<Message>();

  messagesListener: Subscription;

  constructor(
    private messageService: MessageService,
    private changeDetectionRef: ChangeDetectorRef
  ) {
    this.messagesListener = this.messageService.messagesChange
      .pipe(takeUntil(this.unSubAll$))
      .subscribe((messages) => {
        this.messages = messages;
        this.changeDetectionRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.unSubAll$.next();
    this.unSubAll$.complete();
  }
}
