import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { LoaderService } from '../shared/services/loader.service';
import { SessionStorageService } from '../shared/services/session-storage.service';

@Component({
  selector: 'kin-begin',
  templateUrl: './begin.component.html',
})
export class BeginComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit() {
    this.loaderService.enable();
    if (this.authService.retrieveToken() !== null) {
      this.sessionStorageService.remove('pages');
      this.router.navigate(['quote']);
    }
  }
}
