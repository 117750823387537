import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * This service is a wrapper around the Browser's LocalStorage API.
 * More Info: https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
 */

@Injectable()
export class LocalStorageService {
  localStorageSubscription = new BehaviorSubject<string>('');

  constructor() {}

  /**
   * @param key - Key name to identify by
   * @param value - Value associated with the key
   * @desc Store the key value in local storage
   */
  save(key: string, value: string) {
    window.localStorage.setItem(key, value);
    this.localStorageSubscription.next(key);
  }

  /**
   * @param key - Key name to identify by
   * @returns value of local storage key
   */
  retrieve(key: string) {
    return window.localStorage.getItem(key);
  }

  /**
   * @param key - Key to identify by
   * @desc Removes an item from localStorage based on the key param.
   */
  remove(key: string) {
    window.localStorage.removeItem(key);
  }

  /**
   * @desc Removes all localStorage items.
   */
  clear() {
    window.localStorage.clear();
  }
}
