import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ExternalScriptService } from './external-scripts.service';
import { RequestService } from './request.service';

declare global {
  interface Window {
    Accept: {
      dispatchData: (secureData: SecureData, responseHandler: ResponseHandler) => void;
    };
  }
}

export type ResponseHandler = (response: any) => void;

export interface SecureData {
  authData: AuthData;
  cardData: CardData;
}
export interface AuthData {
  clientKey: string;
  apiLoginID: string;
}
export interface CardData {
  cardNumber: string;
  month: string;
  year: string;
  cardCode: string;
  zip: string;
  fullName: string;
}

interface OpaqueData {
  dataDescriptor: string;
  dataValue: string;
}

export interface AcceptJSResponse {
  messages: {
    resultCode: 'Ok' | 'Error';
    message: {
      code: string;
      text: string;
    }[];
  };
  opaqueData: OpaqueData;
}

@Injectable()
export class PaymentService {
  private loaded = false;

  constructor(
    private externalScriptService: ExternalScriptService,
    private requestService: RequestService
  ) {}

  public initialize() {
    this.externalScriptService
      .load('authorize-accept')
      .then(() => {
        // TODO do we want to check loaded before enabling CC form?
        this.loaded = true;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  public dispatchData(cardData: CardData, responseHandler: ResponseHandler): void {
    this.requestService
      .get<any>(`${environment.baseUrl + environment.apiPrefix}/credit_card_authentication`)
      .toPromise()
      .then((data) => {
        window.Accept.dispatchData(
          {
            cardData,
            authData: {
              clientKey: data.public_client_key,
              apiLoginID: data.login_id,
            },
          },
          responseHandler
        );
      });
  }

  public submitSuccessData({ dataValue, dataDescriptor }: OpaqueData, payment_schedule: string) {
    this.requestService
      .post(`${environment.baseUrl + environment.apiPrefix}/credit_cards`, {
        value: dataValue,
        descriptor: dataDescriptor,
        payment_schedule,
      })
      .toPromise();
  }
}
