import { Component, EventEmitter, Output } from '@angular/core';
import { AutoRadioQuestion } from '../../models/form-elements/auto-radio';
import { BaseFieldComponent } from '../base-field.component';

@Component({
  selector: 'kin-form-auto-radio',
  templateUrl: './auto-radio.component.html',
})
export class FormAutoRadioComponent extends BaseFieldComponent<AutoRadioQuestion> {
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() autoSubmit: EventEmitter<any> = new EventEmitter<any>();

  onClick(e: Event, value: string) {
    this.formService.emitCallerElementId(this.fieldData.value);
    this.pageBuilderService.options.next({ removeSubmit: true });
    this.form.get(this.fieldData.name).setValue(value);
    this.formService.walkChildren(this.form, this.fieldData);
    if (!this.fieldData.hasEligibleChildren) {
      this.autoSubmit.emit('');
    }
  }
}
