<div
  [class]="'tooltip__container tooltip__container--' + size"
  [attr.aria-describedby]="id"
  (ngkClickOutside)="close()"
>
  <button
    class="tooltip__container__trigger"
    type="button"
    (click)="this.toggle($event)"
    [attr.aria-controls]="id"
  >
    <kin-svg [name]="helpText.icon || ''" [class]="'icon icon__secondary icon__' + size"></kin-svg>
    <span class="sr-only">Open Tooltip</span>
  </button>
  <div
    #tooltip
    class="tooltip"
    [ngClass]="{ active: isActive }"
    tabindex="-1"
    aria-label="Tooltip"
    [attr.aria-expanded]="isActive"
  >
    <button
      class="tooltip__close"
      type="button"
      (click)="this.toggle($event)"
      [attr.aria-controls]="id"
    >
      <kin-svg name="x" class="icon"></kin-svg>
      <span class="sr-only">Close Tooltip</span>
    </button>
    <p class="tooltip__text" role="tooltip" [id]="id + '__tooltip'">
      {{ helpText.text
      }}<span *ngIf="helpText.url"
        >&nbsp;<a
          [href]="helpText.url"
          class="text__link text__secondary-hover popout"
          target="_blank"
          rel="noopener noreferrer"
          >Learn More</a
        ></span
      >
    </p>
  </div>
</div>
