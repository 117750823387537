/* eslint-disable max-classes-per-file */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import { NgModule } from '@angular/core';
import { NgkLoggerDataDogConfig, NgkLoggerError, NgkLoggerEvent } from '../logger.types';
import { NgKLoggerService } from '../logger.service';

/**
 * Logger service is a unit testing helper to mock the logger functions so no real integrations are fired off
 */
export class MockNgKLoggerService {
  public ddIsInitialized = false;

  public enableDebugger() {}

  public initializeDataDog(config: NgkLoggerDataDogConfig) {
    this.ddIsInitialized = true;
  }

  public error(event: NgkLoggerError) {}

  public event(event: NgkLoggerEvent) {}

  public consoleMsg(event: NgkLoggerEvent) {}
}

/**
 * Module to help make testing easier
 */
@NgModule({
  providers: [{ provide: NgKLoggerService, useClass: MockNgKLoggerService }],
})
export class NgKLoggerServiceTestingModule {}
