import { ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { AriaHelper } from '../../utils/aria-helper';
import type { SvgComponent } from '../svg/svg.component';

/**
 * This component is used to render a button that opens a tooltip attached to it.
 * Tooltip content can be projected or text set with `content`.
 * Requires `icon` to be set to compile.
 * @example
 * ```html
 * <ngk-tooltip
 *    icon="hint"
 *    text="It looks like you're rendering a tooltip. Would you like help?">
 * </ngk-tooltip>
 *```
 * @requires '@angular/cdk/overlay-prebuilt.css' in global styles
 */
@Component({
  selector: 'ngk-tooltip[icon]',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @ViewChild('button') button!: TemplateRef<any>;

  /**
   * Required. This will not compile if not set.
   * The icon of the button that opens the tooltip.
   */
  @Input() icon!: SvgComponent['name'];

  /**
   * Text content of the tooltip.
   */
  @Input() text?: string;

  /**
   * Tooltip content ID. Optional. If not set, one will be generated.
   * Used to link the tooltip to the button with aria-describedby.
   */
  @Input() id: string = AriaHelper.getGeneratedId('ngk-tooltip');

  /**
   * Sets the size of the button icon.
   * Corresponds to styles in _icon.scss.
   */
  @Input() iconSize:
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge'
    | 'xxxlarge'
    | 'fit'
    | 'scale-with-font' = 'medium';

  /**
   * Set this to true to make the tooltip in line with text.
   * Defaults to false, which behaves like a block element.
   */
  @Input() iconInline: boolean = false;

  /**
   * If true, the tooltip content will be shown. Defaults to false.
   */
  @Input() showContent: boolean = false;

  /**
   * Hidden button text. Used to provide context to screen readers.
   * Defaults to "Open Tooltip".
   */
  @Input() buttonScreenReaderText: string = 'Open Tooltip';

  /**
   * Used to position the caret of the tooltip so it looks like it is pointing at the origin.
   * The strings correlate to class names in tooltip.component.scss.
   */
  caretPosition: {
    x: 'left' | 'right';
    y: 'top' | 'bottom';
  } = { x: 'left', y: 'top' };

  /**
   * A list of positions the tooltip can be placed around the origin.
   */
  readonly tooltipPositions: Readonly<ConnectedPosition>[] = [
    {
      // Bottom right
      originX: 'center',
      originY: 'bottom',
      overlayY: 'top',
      overlayX: 'start',
    },
    {
      // Bottom left
      originX: 'center',
      originY: 'bottom',
      overlayY: 'top',
      overlayX: 'end',
    },
    {
      // Top left
      originX: 'center',
      originY: 'top',
      overlayY: 'bottom',
      overlayX: 'end',
    },
    {
      // Top right
      originX: 'center',
      originY: 'top',
      overlayY: 'bottom',
      overlayX: 'start',
    },
  ];

  constructor(private readonly ref: ChangeDetectorRef) {}

  /**
   * Handles the click event on the button to toggle the tooltip open and closed.
   */
  handleButtonClick(e: Event) {
    e.stopPropagation();
    this.showContent = !this.showContent;
  }

  /**
   * Handles when the position of the tooltip changes.
   * Updates {@link caretPosition} to account for the new positioning.
   */
  handlePositionChange(event: ConnectedOverlayPositionChange) {
    if (event.connectionPair.overlayX === 'start') {
      this.caretPosition.x = 'left';
    } else if (event.connectionPair.overlayX === 'end') {
      this.caretPosition.x = 'right';
    }

    if (event.connectionPair.overlayY === 'top') {
      this.caretPosition.y = 'top';
    } else if (event.connectionPair.overlayY === 'bottom') {
      this.caretPosition.y = 'bottom';
    }
    this.ref.detectChanges();
  }

  /**
   * Handles a click occurs outside the tooltip to close it.
   */
  handleOverlayOutsideClick(e: Event) {
    this.showContent = false;
    /**
     * If the click was on own button, block click event to prevent the modal from staying open
     */
    if (this.button.elementRef.nativeElement.contains(e.target)) {
      e.stopPropagation();
    }
  }
}
