import { Component, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  throttleTime,
} from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { ExternalScriptService } from '../../../../services/external-scripts.service';
import { RequestService } from '../../../../services/request.service';
import { AutocompleteOption } from '../../models/form-elements/autocomplete';
import { RemoteAutocompleteQuestion } from '../../models/form-elements/remote-autocomplete';
import { PageBuilderService } from '../../page-builder.service';
import { BaseFieldComponent } from '../base-field.component';
import { FormService } from '../form.service';

@Component({
  selector: 'kin-form-remote-autocomplete',
  templateUrl: 'remote-autocomplete.component.html',
  styleUrls: ['../autocomplete/autocomplete.component.scss'],
})
export class FormRemoteAutocompleteComponent
  extends BaseFieldComponent<RemoteAutocompleteQuestion>
  implements OnInit, OnDestroy
{
  inputSubject = new Subject<string>();

  constructor(
    protected externalScriptService: ExternalScriptService,
    public formService: FormService,
    protected pageBuilderService: PageBuilderService,
    protected requestService: RequestService
  ) {
    super(externalScriptService, formService, pageBuilderService);
  }

  ngOnInit() {
    merge(
      // Immediately search after two characters are input, then make a request every 1000ms while input is being made
      this.inputSubject.pipe(
        filter((input) => input.length > 1),
        throttleTime(1000)
      ),
      // Make a request 350ms after input stops
      this.inputSubject.pipe(debounceTime(350))
    )
      .pipe(
        distinctUntilChanged(),
        switchMap((searchText) => {
          const searchParams = new URLSearchParams();
          searchParams.append('search', searchText);
          // fetch autocomplete suggestions
          return this.requestService.get<AutocompleteOption[]>(
            `${
              environment.baseUrl + environment.apiPrefix + this.fieldData.endpoint
            }?${searchParams.toString()}`,
            true
          );
        })
      )
      .subscribe((suggestionData) => {
        this.fieldData.options = suggestionData;
      });
  }

  handleFocus = this.handleInput;

  handleInput(evt: Event) {
    const input = (evt.target as HTMLInputElement)?.value;
    if (input != null) {
      this.inputSubject.next(input);
    }
  }

  ngOnDestroy() {
    this.inputSubject.complete();
  }
}
