import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { constants } from '../constants/constants';
import { LoaderService } from './loader.service';
import { MessageService } from './message.service';

export const FRIENDLY_ERROR_MSG = `Hmm, something's not working right. Give us a call at
                           <a href="tel:${constants.PHONE_NUMBER}" class="text__bold">${constants.PHONE_NUMBER}</a> or email
                           <a href="mailto:${constants.SUPPORT_EMAIL}" class="text__bold">${constants.SUPPORT_EMAIL}</a>
                           so we can finish this up for you.`;

// This service is meant to be a wrapper of the Angular HttpClient.
// Should Angular HttpClient change at any time (which has happened in the past, see v4), we can update it in one place.
// This also allows us to create global error handling procedures, such as showing the global kin-message if an issue occurs.
@Injectable()
export class RequestService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private loaderService: LoaderService
  ) {}

  // TODO: Shape responses with interfaces and make use of these generics
  post(url: string, body: unknown, failSilently: boolean = false): Observable<any> {
    return this.http.post(url, body).pipe(
      retry(constants.REQUEST_RETRIES),
      catchError((error) =>
        failSilently
          ? []
          : this.handleError(error, { displayFriendlyError: this.isPagesApiRequest(url) })
      )
    );
  }

  patch(
    url: string,
    body: unknown,
    token?: string,
    failSilently: boolean = false
  ): Observable<any> {
    let headers = new HttpHeaders();

    if (token) {
      headers = headers.set('Authorization', `Bearer ${token}`);
    }

    return this.http.patch(url, body, { headers }).pipe(
      retry(constants.REQUEST_RETRIES),
      catchError((error) =>
        failSilently
          ? []
          : this.handleError(error, { displayFriendlyError: this.isPagesApiRequest(url) })
      )
    );
  }

  get<T>(
    url: string,
    failSilently: boolean = false,
    params = {},
    handleError = true
  ): Observable<T> {
    return this.http.get<T>(url, { params }).pipe(
      retry(constants.REQUEST_RETRIES),
      catchError((error) => {
        if (failSilently) {
          return [];
        }

        if (handleError) {
          return this.handleError(error, { displayFriendlyError: this.isPagesApiRequest(url) });
        }

        return throwError(() => error);
      })
    );
  }

  // This method is run above in every GET / POST scenario, and returns a message based upon the error.
  // To disable this, use the failSilently option in your .get() or .post()
  private handleError(error: HttpErrorResponse, options: { displayFriendlyError?: boolean } = {}) {
    this.loaderService.disable();
    this.messageService.clearMessages();

    this.messageService.addMessage({
      name: options.displayFriendlyError ? '' : this.defineErrorName(error.status),
      message: options.displayFriendlyError ? FRIENDLY_ERROR_MSG : this.defineErrorMessage(error),
      type: 'error',
    });

    return throwError(
      'An error other than HTTP 400, 404, 422, or 500 occurred; please try again later.'
    );
  }

  private isPagesApiRequest(url: string): boolean {
    return url.includes(`${environment.baseUrl + environment.apiPrefix}/pages`);
  }

  private defineErrorName(status: number): string {
    switch (status) {
      case 400:
        return 'Bad Request';
      case 422:
        return 'Validation Error';
      default:
        return `Server Error (${status || 'Not Defined'})`;
    }
  }

  private defineErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 400:
        return error.error.error.message;
      case 422:
        return error.message;
      default:
        return error.error.message || FRIENDLY_ERROR_MSG;
    }
  }
}
