<label class="input-textbox" for="{{ fieldData.id }}" [formGroup]="form">
  <input 
    class="input-textbox--input"
    [ngClass]="{'fs-exclude': fieldData.fullStoryExclude}"
    placeholder="{{ fieldData.placeholder }}"
    [required]="fieldData.required" 
    [attr.inputmode]="fieldData.inputmode"
    [attr.maxlength]="fieldData.maxlength"
    [attr.minlength]="fieldData.minlength"
    [attr.aria-describedby]="describedBy"
    [formControlName]="fieldData.name"
    [kinMask]="fieldData.mask || '(000) 000-0000'"
    type="{{ fieldData.type }}"
    name="{{ fieldData.name }}"
    id="{{ fieldData.id }}"
    [attr.autocomplete]="fieldData.autocomplete">
  <span class="input-textbox--label">{{ fieldData.label }}</span>
</label>
