import { Component, OnInit } from '@angular/core';
import { constants } from '../constants/constants';

@Component({
  selector: 'kin-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  phoneNumber: string;

  ngOnInit() {
    this.phoneNumber = constants.PHONE_NUMBER;
  }
}
