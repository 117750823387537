import { Component, OnInit, AfterContentInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CheckboxQuestion } from '../../models/form-elements/checkbox';
import { BaseFieldComponent } from '../base-field.component';

export type CheckBoxValue = { [key: string]: boolean };

@Component({
  selector: 'kin-form-checkbox',
  templateUrl: './checkbox.component.html',
})
export class FormCheckboxComponent
  extends BaseFieldComponent<CheckboxQuestion>
  implements OnInit, AfterContentInit
{
  singleInput = false;

  checkboxGroup!: FormGroup;

  ngOnInit() {
    this.singleInput = this.fieldData.options.length === 1;
  }

  ngAfterContentInit() {
    this.checkboxGroup = this.form[this.fieldData.name] as FormGroup;
  }

  onChange() {
    this.setControlValue();
  }

  private setControlValue() {
    const { value } = this.checkboxGroup;
    if (this.singleInput) {
      const singleInputValue = Object.values(value)[0];
      this.formControl.setValue(singleInputValue);
      return;
    }

    this.formControl.setValue(value);
  }
}
