import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { constants } from '../constants/constants';
import { LoaderService } from '../services/loader.service';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'kin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('header') header: ElementRef;

  headerContent: string;

  headerContentListener: Subscription;

  constants = constants;

  displayPhone = true;

  private unSubAll$ = new Subject<void>();

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.unSubAll$)).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.loaderService.enable(); // This kicks in if navigation takes longer than 250ms
      }

      if (event instanceof NavigationEnd) {
        this.loaderService.disable();
        this.messageService.clearMessages();
        (document.querySelector('a') as HTMLElement).focus();
        (this.header.nativeElement as HTMLElement).scrollIntoView(true);
        this.checkPhone();
      }
    });
    this.checkPhone();
  }

  ngOnDestroy() {
    this.unSubAll$.next();
    this.unSubAll$.complete();
  }

  checkPhone(): void {
    if (
      window.location.href.includes('/thanks/trying-us') ||
      window.location.href.includes('/thanks/coming-soon') ||
      window.location.href.includes('/thanks/ca-landing')
    ) {
      this.displayPhone = false;
    }
  }
}
