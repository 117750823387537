/**
 * Parses errors and objects to avoid logging strings like "[object]"
 * @param error - can be string, object, array, etc.  See spec for examples & support
 */
export function ngkLoggerParseError(error: unknown): string {
  const fallbackMsg = 'Unknown Error';

  if (typeof error === 'string') {
    return error;
  }

  if (error instanceof Error) {
    return error?.message ? error.message : fallbackMsg;
  }

  if (error instanceof Object) {
    return JSON.stringify(error);
  }

  return fallbackMsg;
}
