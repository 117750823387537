import { Component, Input } from '@angular/core';

@Component({
  selector: 'kin-base-page-element',
  template: '',
})
export class BasePageElementComponent<T> {
  @Input() elementData: T;

  constructor() {}

  // TODO: Determine how much of this is necessary for PageElement
  // - We may want to relate certain elements to form elements. If that's the case,
  //   we may want to be able to dynamically assign aria-describedby
  //
  // get describedBy(): string {
  //   let describedBy = '';
  //   const id = ((this.fieldData as unknown) as Question).id;
  //   if (((this.fieldData as unknown) as Question).hint) {
  //     describedBy += id + '__hint ';
  //   }

  //   if (((this.fieldData as unknown) as Question).description) {
  //     describedBy += id + '__description ';
  //   }

  //   if (((this.fieldData as unknown) as Question).errors) {
  //     describedBy += id + '__errors ';
  //   }

  //   return describedBy;
  // }
}
