<div class="wrapper wrapper__bordered">
  <kin-message></kin-message>
  <div class="grid--fluid">
    <div class="row">
      <div class="col-sm-10 col-sm-offset-1">
        <div class="v-list">
          <h1 class="text text--36">404 Page Not Found</h1>
          <p>
            Please <a href="/" class="text text__link text__primary">start over</a> to get a quote with Kin, or call us at <a class="text text__link text__primary" href="tel:{{ phoneNumber }}">{{ phoneNumber }}</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>