import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ModalComponent } from './components/modal/modal.component';
import { MessageComponent } from './components/message/message.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SvgComponent } from './components/svg/svg.component';
import { KinspectorMiniComponent } from './components/kinspector-mini/kinspector-mini.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { AddressBarComponent } from './components/address-bar/address-bar.component';
import { QuoteCardComponent } from './components/quote-card/quote-card.component';
import { HeaderCircleComponent } from './components/header-circle/header-circle.component';
import { HeadingComponent } from './heading/heading.component';
import { TrustpilotComponent } from '../landing/trustpilot/trustpilot.component';
import { MessageWithUnderlineComponent } from './components/message-with-underline/message-with-underline.component';
import { StaticLoaderComponent } from './components/static-loader/static-loader.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    PageNotFoundComponent,
    ProgressBarComponent,
    AddressBarComponent,
    MessageComponent,
    LoaderComponent,
    SvgComponent,
    KinspectorMiniComponent,
    QuoteCardComponent,
    HeaderCircleComponent,
    HeadingComponent,
    TrustpilotComponent,
    MessageWithUnderlineComponent,
    StaticLoaderComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
  exports: [
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    PageNotFoundComponent,
    ProgressBarComponent,
    AddressBarComponent,
    QuoteCardComponent,
    MessageComponent,
    LoaderComponent,
    SvgComponent,
    HeaderCircleComponent,
    HeadingComponent,
    TrustpilotComponent,
    MessageWithUnderlineComponent,
    StaticLoaderComponent,
  ],
})
export class SharedModule {}
