<div class="alert-bar" *ngIf="isCustomUrl">
  <div class="text text__center text__primary">
    Using {{ baseUrl }} as API Endpoint. <button type="button" class="text text__link--borderless" (click)="reset()">Reset</button>
  </div>
</div>
<kin-modal #modal>
  <h1 header>Kinspector Mini</h1>
  <div body>
    <button type="button" class="btn btn__link" (click)="resetSessionStorage()">Reset Session</button>
    <div class="vr vr__x6"></div>
    <dl>
      <dt><span class="text text__bold">Current API URL:</span></dt>
      <dd>{{ baseUrl }}</dd>
    </dl>
    <div class="vr vr__x4"></div>
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="v-list">
        <label for="" class="input-textbox">
          <input class="input-textbox--input" placeholder=" " #endpoint type="text" formControlName="api_endpoint" aria-label="Review App URL">
          <span class="input-textbox--label">Target API URL</span>
        </label>
        <span class="text text__hint"><span class="text text__bold">NOTE: This is only available in non-production environments.</span><br>Paste the URL of the environment you want to target, e.g. https://development.kin.com.<br><span class="text text__bold text__warning">This will start you over at the beginning of the funnel.</span></span>
      </div>
      <input type="hidden" formControlName="default_endpoint">
    </form>
  </div>
  <div footer>
    <div class="split">
      <div class="split--left">
        <button type="button" class="btn btn__secondary btn__secondary--gray btn__stretch" (click)="modal.close()">Cancel</button>
      </div>
      <div class="split--right">
        <button class="btn btn__stretch" [disabled]="!isValid" (click)="save()">Save and Restart</button>
      </div>
    </div>
  </div>
</kin-modal>
