import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const TRANSITION_DURATION = '300ms ease-in-out';

export const slideInOutAnimation = trigger('routeSlideInOut', [
  transition(':increment', [
    query(':leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ]),
    query(':enter', [
      style({
        display: 'block',
        transform: 'translateX(100%)',
      }),
    ]),
    group([
      query(':leave', [animate(TRANSITION_DURATION, style({ transform: 'translateX(-100%)' }))]),
      query(':enter', [animate(TRANSITION_DURATION, style({ transform: 'translateX(0)' }))]),
    ]),
  ]),
  transition(':decrement', [
    query(':leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ]),
    query(':enter', [
      style({
        display: 'block',
        transform: 'translateX(-100%)',
      }),
    ]),
    group([
      query(':leave', [animate(TRANSITION_DURATION, style({ transform: 'translateX(100%)' }))]),
      query(':enter', [animate(TRANSITION_DURATION, style({ transform: 'translateX(0)' }))]),
    ]),
  ]),
]);

export const fadeInOutAnimation = trigger('routeFadeInOut', [
  transition(':increment', [
    query(':leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ]),
    query(':enter', [style({ display: 'block', opacity: 0 })]),
    group([
      query(':leave', [animate(TRANSITION_DURATION, style({ opacity: 0 }))]),
      query(':enter', [animate(TRANSITION_DURATION, style({ opacity: 1 }))]),
    ]),
  ]),
]);
