import { Component } from '@angular/core';

@Component({
  selector: 'kin-header-circle',
  templateUrl: './header-circle.component.html',
  styleUrls: ['./header-circle.component.scss'],
  providers: [],
})
export class HeaderCircleComponent {
  constructor() {}
}
