import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

// This CanActivate guard (https://angular.io/api/router/CanActivate) protects the /sandbox route from loading in prod

@Injectable()
export class DevEnvGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return environment.production ? this.router.parseUrl('/') : true;
  }
}
