<fieldset [formGroup]="form">
  <legend [ngClass]="{ 'sr-only': fieldData.labelless }">{{ fieldData.label }}</legend>
  <ul [ngClass]="{ 'h-list': !hasImages }" [className]="gridSize">
    <ng-container *ngFor="let option of fieldData.options; index as i; trackBy: trackByValue">
      <li
        *ngIf="hasImages; else default"
        class="radio-card"
        [ngClass]="{ 'radio-card__full-width': option.image?.type === 'image' }"
      >
        <label for="{{ fieldData.id }}-{{ i }}" class="radio-card--inner">
          <input
            type="radio"
            id="{{ fieldData.id }}-{{ i }}"
            name="{{ fieldData.name }}"
            [attr.aria-describedby]="describedBy"
            (change)="onChange($event, option.value)"
            [value]="option.value"
            [checked]="option.checked"
            [required]="fieldData.required"
          />
          <span
            class="radio-card--inner--image"
            [ngClass]="{ 'radio-card--inner--image__full-width': option.image?.type === 'image' }"
            *ngIf="option.image"
          >
            <kin-svg *ngIf="option.image?.type === 'icon'" [name]="option.image.name"></kin-svg>
            <img
              *ngIf="option.image?.type === 'image'"
              src="/assets/img/images/{{ option.image.name }}"
              alt="{{ option.image.description }}"
            />
          </span>
          <span class="radio-card--inner--background"></span>
          <span
            class="radio-card--inner--text"
            [ngClass]="{
              'radio-card--inner--text__has-tooltip': !!option.helpText && option.helpText.type === 'tooltip'
            }"
            >{{ option.text }}</span
          >
          <span class="radio-card--inner--description">{{ option.description }}</span>
          <span class="radio-card--inner--spacer"></span>
          <!-- TODO clean up this logic; move to helper for all radios to consume -->
          <kin-form-tooltip
            *ngIf="!!option.helpText && option.helpText.type === 'tooltip'"
            [id]="option.value + '__tooltip'"
            [helpText]="option.helpText"
            [form]="form"
            class="tooltip__container"
          ></kin-form-tooltip>
        </label>
      </li>
      <ng-template #default>
        <li>
          <label for="{{ fieldData.id }}-{{ i }}">
            <input
              type="radio"
              id="{{ fieldData.id }}-{{ i }}"
              name="{{ fieldData.name }}"
              [attr.aria-describedby]="describedBy"
              (change)="onChange($event, option.value)"
              [value]="option.value"
              [checked]="option.checked"
              [required]="fieldData.required"
            />
            {{ option.text }}
          </label>
        </li>
      </ng-template>
    </ng-container>
  </ul>
</fieldset>
