<fieldset [formGroup]="form">
  <div class="toggle">
    <input
      class="is-visually-hidden"
      type="checkbox"
      id="{{fieldData.id}}"
      name="{{fieldData.name}}"
      [attr.aria-describedby]="describedBy"
      [formControlName]="fieldData.name"
      (change)="onChange($event)"
      [value]="fieldData.value"
      [required]="fieldData.required">
    <label for="{{fieldData.id}}">
      <!-- TODO at some point it would be cool (or interesting to learn about how) to have these conditions simply respond
      to the existence of ng-content rather than have to pass the htmlContentSupplied boolean -->
      <span *ngIf="!fieldData.htmlContentSupplied" [ngClass]="{'sr-only': fieldData.labelless}">{{fieldData.label}}</span>
      <ng-container *ngIf="fieldData.htmlContentSupplied"><ng-content></ng-content></ng-container>
    </label>
    <span class="toggle-label text__bold">{{toggleLabel}}</span>
  </div>
</fieldset>
