<header #header class="header">
  <div class="header__left">
    <div>
      <div class="header__logos">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </div>
    </div>
    <kin-progress-bar label="quote progress" class="progress-bar--header"></kin-progress-bar>
  </div>
  <div class="header__right">
    <div class="h-list h-list--middle h-list--x3@small h-list--right h-list--bare">
      <div *ngIf="displayPhone" class="header__phone">
        <div class="text__right text__sm">
          <p>Talk to a licensed agent:</p>
        </div>
        <a href="tel:{{ constants.PHONE_NUMBER }}" class="text text__md text__secondary text__link--underline-mobile">
          <div class="h-list h-list--middle h-list--bare">
            <span class="icon-wrapper icon-wrapper__circle">
              <kin-svg [name]="'phone'" [classes]="'icon icon__small'"></kin-svg>
            </span>
            <span class="text__bolder">{{ constants.PHONE_NUMBER }}</span>
          </div>
        </a>
        <div class="text__right text__sm">
          <p class="text__dark-gray">Mon - Fri, 7am-6pm CT<br>Sat, 8am-4pm CT</p>
        </div>
      </div>
    </div>
  </div>
</header>

<ng-template #logo>
  <a href="https://kin.com">
    <img src="assets/img/logos/kin-logo.svg" alt="Kin Logo" class="header__main-logo" />
    <span class="sr-only">Visit homepage</span>
  </a>
</ng-template>
