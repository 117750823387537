import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// This service is a global service to activate the kin-loader.

@Injectable()
export class LoaderService {
  message = 'Loading...';

  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  enable(message?: string) {
    if (message) {
      this.message = message;
    }
    this.loading.next(true);
  }

  disable() {
    this.message = 'Loading...';
    this.loading.next(false);
  }

  toggleLoader() {
    this.loading.next(!this.loading);
  }
}
