import { Component, Input } from '@angular/core';
import { BaseFieldAddonComponent } from '../base-field-addon.component';
import { HelpText } from '../../models/question';

@Component({
  selector: 'kin-form-hint',
  templateUrl: './hint.component.html',
})
export class FormHintComponent extends BaseFieldAddonComponent {
  @Input() helpText: HelpText;
}
