<label class="input-textbox" for="{{ fieldData.id }}" [formGroup]="form">
  <input 
    [readonly]="fieldData.readonly"
    class="input-textbox--input"
    [ngClass]="{'input-textbox--input__always-activate-label': fieldData.alwaysActivateLabel, 'fs-exclude': fieldData.fullStoryExclude}"
    placeholder="{{ fieldData.placeholder }}"
    [required]="fieldData.required"
    [maxlength]="fieldData.maxlength"
    [minlength]="fieldData.minlength"
    [formControlName]="fieldData.name"
    [attr.aria-describedby]="describedBy"
    type="{{ fieldData.type }}"
    [kinMask]="fieldData.mask"
    name="{{ fieldData.name }}"
    id="{{ fieldData.id }}"
    [attr.autocomplete]="fieldData.autocomplete">
  <span class="input-textbox--label">
    {{ fieldData.label }}
    <kin-form-tooltip
      *ngIf="!!fieldData.helpText && fieldData.helpText.type === 'tooltip'"
      [id]="fieldData.value + '__tooltip'"
      [helpText]="fieldData.helpText"
      [form]="form"
      class="tooltip__container"
      size="small"
    ></kin-form-tooltip>
  </span>
</label>
