/* eslint-disable no-console */
import { datadogRum } from '@datadog/browser-rum';
import { Injectable, Inject } from '@angular/core';
import { DATADOG_RUM } from './datadog.token';
import {
  NgkLoggerDataDogConfig,
  NgkLoggerError,
  NgkLoggerEvent,
  NgkLoggerMessage,
} from './logger.types';
import { WINDOW } from './window.token';
import { knownBotPattern } from './bot-patterns';
import { ngkLoggerParseError } from './parse-error';

/**
 * Enables data dog tracking and error handling
 */
@Injectable({
  providedIn: 'root',
})
export class NgKLoggerService {
  public env: string | undefined;

  private debug = false;

  /** internal identifier if data dog is initialized for the application */
  private _ddIsInitialized = false;

  /** publicly exposed getter for if data dog is initialized for the application */
  public get ddIsInitialized() {
    return this._ddIsInitialized;
  }

  constructor(
    @Inject(DATADOG_RUM) private dataDog: typeof datadogRum,
    @Inject(WINDOW) readonly injectedWindow: Partial<Window> | undefined
  ) {}

  /**
   * Enables more logging around events for the session
   */
  public enableDebugger() {
    this.debug = true;
    this.debugMsg('debugger enabled!');
  }

  /**
   * Initialize Datadog
   * should only be called once
   */
  public initializeDataDog({
    applicationId,
    clientToken,
    service,
    activeUrls,
    site = 'datadoghq.com',
    filterBots = true,
    sessionReplaySampleRate = 100, // ms
    trackUserInteractions = true,
    trackResources = true,
    trackLongTasks = true,
    trackFrustrations = true,
    defaultPrivacyLevel = 'mask-user-input',
  }: NgkLoggerDataDogConfig) {
    // bot detection
    this.debugMsg(`Debug mode is enabled - bot detection is turned ${filterBots ? 'on' : 'off'}.`);
    if (!this.debug && filterBots && this.isKnownBot()) {
      this.consoleMsg({
        context: 'Initialize',
        message: 'Known Bot detected, not initializing Datadog',
      });
      return;
    }

    if (this.ddIsInitialized) {
      this.consoleMsg({ context: 'Initialize', message: 'Datadog is already initialized' });
      return;
    }

    // Confirm debugging is off and the host is valid, if not Logger will not init Datadog
    this.env = this.getValidHost(activeUrls);
    if (!this.env) {
      this.debugMsg(`This is not a valid activeUrl: ${this.env}`);
      this.consoleMsg({
        context: 'Initialize',
        message: 'Datadog is not enabled for this environment',
      });
      return;
    }

    // connect to data dog
    this.dataDog.init({
      env: this.env,
      silentMultipleInit: true,
      sessionSampleRate: 100, // ms
      applicationId,
      clientToken,
      site,
      service,
      sessionReplaySampleRate,
      trackUserInteractions,
      trackFrustrations,
      trackResources,
      trackLongTasks,
      defaultPrivacyLevel,
      enableExperimentalFeatures: ['feature_flags'],
    });
    this._ddIsInitialized = true;
    this.debugMsg('datadog initialized');

    if (sessionReplaySampleRate) {
      this.dataDog.startSessionReplayRecording();
      this.debugMsg('datadog replay recording is on');
    } else {
      this.debugMsg('datadog replay recording is off');
    }
  }

  /**
   * Error Event
   * @description use for errors or issues that are unexpected
   */
  public error(eventDetails: NgkLoggerError) {
    const parsedMsg = ngkLoggerParseError(eventDetails.message);
    const eventMessage = `${eventDetails.priority}[${eventDetails.context}]: ${parsedMsg}`;

    if (this.ddIsInitialized) {
      this.dataDog.addError(eventMessage);
      this.debugMsg(`sent error: ${eventMessage}`);
      // data dog isn't available
    } else {
      this.notSentToDD({
        context: `Error-${eventDetails.priority}-${eventDetails.context}`,
        message: parsedMsg,
      });
    }
  }

  /**
   * Analytics Event
   * @description use to track analytics or events that are expected to happen
   */
  public event(eventDetails: NgkLoggerEvent) {
    const metaDataStr: string = `${eventDetails.name} ${
      eventDetails.metaData ? JSON.stringify(eventDetails.metaData) : ''
    }`;
    if (this.ddIsInitialized) {
      this.dataDog.addAction(eventDetails.name, eventDetails.metaData);
      this.debugMsg(`sent event: ${metaDataStr}`);
      // data dog isn't available
    } else {
      this.notSentToDD({ context: 'Event', message: metaDataStr });
    }
  }

  /**
   * Uniform way to log messages to the console
   * - this method does not require DD to be Initialized
   */
  public consoleMsg(eventDetails: NgkLoggerMessage) {
    const parsedMsg = ngkLoggerParseError(eventDetails.message);
    console.groupCollapsed(
      '%c🔍 NgK Logger Msg',
      'color: #757575; font-size: 12px; font-family: arial'
    );
    console.info(`Context: ${eventDetails.context}`);
    console.info(`%cMessage: ${parsedMsg}`, 'font-weight: bold');
    console.groupEnd();
  }

  /**
   * Private method to warn user about data dog not being initialized
   */
  private notSentToDD(eventDetails: NgkLoggerMessage) {
    const parsedMsg = ngkLoggerParseError(eventDetails.message);
    console.groupCollapsed(
      '%c🔍 NgK Logger [Not Sent to DD]',
      'color: #757575; font-size: 12px; font-family: arial'
    );
    console.info(`Context: ${eventDetails.context}`);
    console.info(`%cMessage: ${parsedMsg}`, 'font-weight: bold');
    console.groupEnd();
  }

  /**
   * Private method to log debug messages if enabled
   * @todo: add to its own utility service so we can leverage everywhere and not require logger to do it.
   */
  private debugMsg(message: string) {
    if (this.debug) {
      console.group(
        '%c🔍 NgK Logger [debugger]',
        'color: #757575; font-size: 12px; font-family: arial'
      );
      console.info(`%cMessage: ${message}`, 'font-weight: bold');
      console.groupEnd();
    }
  }

  /**
   * Check for a Valid Host URL
   * @returns host string to be used as the env for datadog
   */
  private getValidHost(validUrls: string[]): string | undefined {
    const clientHost = this.injectedWindow?.location?.host;
    // if in debug mode, always return host
    if (this.debug) {
      this.debugMsg(
        'Debug mode is enabled - Datadog is enabled for every environment.  **do not leave this on for production**'
      );
      return clientHost ?? 'debug-host';
    }

    // if host is undefined, will return false
    if (!clientHost) {
      this.consoleMsg({ context: 'Initialize', message: 'Unable to determine host url' });
      return undefined;
    }

    // check each URL to see if it is valid for dd to log for
    let env!: string;
    if (validUrls) {
      validUrls.forEach((url) => {
        if (clientHost.includes(url)) {
          env = url;
        }
      });
    }
    return env;
  }

  /**
   * check if userAgent matches known botPatterns
   * @returns true if userAgent is a bot, false if OK
   * source: https://docs.datadoghq.com/real_user_monitoring/guide/identify-bots-in-the-ui/
   */
  private isKnownBot(): boolean {
    if (this.injectedWindow?.navigator) {
      const regex = new RegExp(knownBotPattern, 'i');
      return regex.test(this.injectedWindow?.navigator?.userAgent);
    }
    return false;
  }
}
