import { InjectionToken } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Injection token for Datadog to have an easier time mocking in unit tests
 */
export const DATADOG_RUM = new InjectionToken<typeof datadogRum>('DatadogRum', {
  providedIn: 'root',
  factory: () => datadogRum,
});
