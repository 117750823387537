import {
  Directive,
  ElementRef,
  Input,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter,
  NgZone,
} from '@angular/core';

/**
 * Sets a ResizeObserver on the host and emits when host is resized.
 *
 * @param {string} [box=''] box property of resize observer options.
 * @example
 * ```html
 * <div ngkResizeObserver box="border-box" (onResize)="handleResize($event)">
 *   <app-kin-non-prod-banner></app-kin-non-prod-banner>
 *   <app-kin-header></app-kin-header>
 * </div>
 *```
 */
@Directive({
  selector: '[ngkResizeObserver]',
})
export class ResizeObserverDirective implements OnInit, OnDestroy {
  @Input()
  box: 'content-box' | 'border-box' | 'device-pixel-content-box' = 'content-box';

  @Output()
  onResize = new EventEmitter<ResizeObserverEntry[]>();

  private resizeObserver!: ResizeObserver;

  constructor(private elementRef: ElementRef<HTMLElement>, private zone: NgZone) {}

  ngOnInit() {
    this.resizeObserver = new ResizeObserver((entries) => {
      // Run within zone, as zone.js doesn't patch resize observer
      this.zone.run(() => {
        this.onResize.emit(entries);
      });
    });
    this.resizeObserver.observe(this.elementRef.nativeElement, { box: this.box });
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }
}
