import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  accountForUTC,
  getLastMonth,
  getNextMonth,
  getFirstDayOfMonth,
  calculateLastDayOfMonth,
  daysInMonth,
  changeDateByDays,
  changeDateByYears,
  getDateWithoutTime,
  getBeginningOfMonth,
} from '../../../../utilities/date';
import { isNumber } from '../../../../utilities/number';
import { DateQuestion } from '../../models/form-elements/date';
import { BaseFieldComponent } from '../base-field.component';

interface DatePickerRange {
  startDate: Date;
  endDate: Date;
}

interface Calendar {
  date?: Date;
  active: boolean;
  year: number;
}

interface CalendarDays extends Calendar {
  day: number;
  month: number;
}

interface CalendarMonths extends Calendar {
  month: number;
  year: number;
}

/*
Adapted for Angular from https://github.com/kin/dot-com/blob/master/app/javascript/components/shared/datepicker/datepicker.ts
*/

@Component({
  selector: 'kin-form-datepicker',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['datepicker.component.scss'],
})
export class FormDatePickerComponent extends BaseFieldComponent<DateQuestion> implements OnInit {
  @Input() isActive = false;

  @Input() id = 'datepicker__date';

  @ViewChild('datepicker') datepickerElement: ElementRef;

  @ViewChild('buttonsToFocus') buttonsToFocus: ElementRef;

  @ViewChild('textbox') textbox: ElementRef;

  @ViewChild('trigger') trigger: ElementRef;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  range: DatePickerRange = {
    startDate: changeDateByYears(-150),
    endDate: changeDateByYears(150),
  };

  today: Date = getDateWithoutTime();

  current: Date;

  selectedDate: Date;

  monthView = true;

  yearView = false;

  decadeView = false;

  days: CalendarDays[][] = [];

  months: CalendarMonths[][] = [];

  years: Calendar[][] = [];

  MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  DAYS_OF_WEEK_ABBREVIATED = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  ngOnInit() {
    this.current = getDateWithoutTime();
    this.parseRange();
    this.parseCurrent();
    this.buildWeeks();
  }

  @HostListener('document:keydown.escape') handleEscapeKeypress() {
    this.isActive = false;
  }

  disableClickEvents(e: Event) {
    e.preventDefault();
  }

  handleAssociatedInputChange(e: CustomEvent) {
    const { value } = e.detail;

    if (!Number.isNaN(new Date(value).getFullYear())) {
      this.selectedDate = accountForUTC(new Date(value));
    }
  }

  public toggle() {
    if (this.isActive) {
      this.close();
    } else {
      this.open();
    }
  }

  public open() {
    this.buildWeeks();

    this.isActive = true;
  }

  public close() {
    this.trigger.nativeElement.focus();
    this.isActive = false;
  }

  buildDaysInMonth(month: number, year: number, active = true): CalendarDays[] {
    const days = [];
    const numberOfDays = daysInMonth(month, year);
    for (let i = 0; i < numberOfDays; i += 1) {
      days.push({
        date: new Date(year, month, i + 1),
        day: i + 1,
        month,
        year,
        active,
      });
    }

    return days;
  }

  buildWeeks() {
    this.days = [];

    const currentMonth = this.current.getMonth();
    const currentYear = this.current.getFullYear();
    const days = this.buildDaysInMonth(currentMonth, currentYear);
    const lastDayOfMonth = calculateLastDayOfMonth(currentMonth, currentYear);
    const lastMonth = getLastMonth(currentMonth, currentYear);
    const nextMonth = getNextMonth(currentMonth, currentYear);
    const firstDayOfMonth = getFirstDayOfMonth(currentMonth, currentYear);

    const beforeDays = this.buildAdjacentDays(
      lastMonth.getMonth(),
      lastMonth.getFullYear(),
      firstDayOfMonth
    );
    const endDays = this.buildAdjacentDays(
      nextMonth.getMonth(),
      nextMonth.getFullYear(),
      lastDayOfMonth,
      true
    );

    let scaffold: CalendarDays[] = [];
    scaffold = beforeDays.concat(days);
    scaffold = scaffold.concat(endDays);

    let temp: CalendarDays[] = [];

    scaffold.forEach((day, i) => {
      temp.push(day);
      if (Number.isInteger((i + 1) / 7)) {
        this.days.push(temp);
        temp = [];
      }
    });
  }

  buildAdjacentYears() {
    this.years = [];

    const yearsToDisplay = 12;
    const currentYearDisplayOrdinal = 8;

    const decadeStart = this.current.getFullYear() - (currentYearDisplayOrdinal - 1);
    const decadeEnd = this.current.getFullYear() + (yearsToDisplay - currentYearDisplayOrdinal + 1);
    const scaffold: Calendar[] = [];

    for (let i = 0; i < decadeEnd - decadeStart; i += 1) {
      scaffold.push({
        date: new Date(decadeStart + i, 0, 1),
        year: decadeStart + i,
        active: this.current.getFullYear() === decadeStart + i,
      });
    }

    let temp: Calendar[] = [];

    scaffold.forEach((year, i) => {
      temp.push(year);
      if (Number.isInteger((i + 1) / 3)) {
        this.years.push(temp);
        temp = [];
      }
    });
  }

  buildAdjacentDays(
    month: number,
    year: number,
    dayOfWeek: number,
    forwards: boolean = false
  ): CalendarDays[] {
    let daysInAdjacentMonth = this.buildDaysInMonth(month, year, false);

    if (forwards) {
      daysInAdjacentMonth = daysInAdjacentMonth.slice(0, Math.abs(6 - dayOfWeek));
    } else {
      daysInAdjacentMonth = daysInAdjacentMonth.slice(
        daysInAdjacentMonth.length - dayOfWeek,
        daysInAdjacentMonth.length
      );
    }

    return daysInAdjacentMonth;
  }

  buildMonths() {
    const year = this.current.getFullYear();
    const month = this.current.getMonth();

    this.months = [];

    const scaffold: CalendarMonths[] = [];

    for (let i = 0; i < 12; i += 1) {
      scaffold.push({
        month: i,
        active: this.current.getMonth() === month,
        year,
      });
    }

    let temp: CalendarMonths[] = [];

    scaffold.forEach((tempMonth, i) => {
      temp.push(tempMonth);
      if (Number.isInteger((i + 1) / 3)) {
        this.months.push(temp);
        temp = [];
      }
    });
  }

  parseRange() {
    const { minAgeDays, maxAgeDays, minAgeYears, maxAgeYears } = this.fieldData?.validations || {};
    if (isNumber(minAgeDays) || isNumber(maxAgeDays)) {
      this.handleLimits(minAgeDays, maxAgeDays, changeDateByDays);
    } else if (isNumber(minAgeYears) || isNumber(maxAgeYears)) {
      this.handleLimits(minAgeYears, maxAgeYears, changeDateByYears);
    }
  }

  handleLimits(min, max, changeFunction) {
    if (isNumber(max)) {
      this.range.startDate = changeFunction(-max, this.today);
    }
    if (isNumber(min)) {
      this.range.endDate = changeFunction(-min, this.today);
    }
  }

  parseCurrent() {
    if (this.today <= this.range.startDate) {
      this.current = getDateWithoutTime(this.range.startDate);
    } else if (this.today >= this.range.endDate) {
      this.current = getDateWithoutTime(this.range.endDate);
    } else {
      this.current = getDateWithoutTime();
    }
  }

  inRange(date: Date) {
    if (this.monthView) {
      return this.range.startDate <= date && date <= this.range.endDate;
    }
    if (this.yearView) {
      const startMonth = getBeginningOfMonth(this.range.startDate);
      const endMonth = getBeginningOfMonth(this.range.endDate);
      const currentMonth = getBeginningOfMonth(date);

      return startMonth <= currentMonth && currentMonth <= endMonth;
    }

    if (this.decadeView) {
      return (
        this.range.startDate.getFullYear() <= date.getFullYear() &&
        date.getFullYear() <= this.range.endDate.getFullYear()
      );
    }
    return undefined;
  }

  compareDay(day1: Date, day2: Date) {
    return (
      day1.getMonth() === day2.getMonth() &&
      day1.getDate() === day2.getDate() &&
      day1.getFullYear() === day2.getFullYear()
    );
  }

  handleState(state: string) {
    if (state === 'decade') {
      this.yearView = false;
      this.monthView = false;
      this.decadeView = true;
      this.buildAdjacentYears();
    } else if (state === 'year') {
      this.yearView = true;
      this.monthView = false;
      this.decadeView = false;
      this.buildMonths();
    } else if (state === 'month') {
      this.yearView = false;
      this.monthView = true;
      this.decadeView = false;
      this.buildWeeks();
    }

    this.buttonsToFocus.nativeElement.focus();
  }

  onDateSelect(e: Event) {
    if (this.monthView) {
      const selectedDay = Number(
        (e.composedPath()[0] as HTMLElement).innerText.replace(/[^0-9]+/g, '')
      );
      this.selectedDate = new Date(this.current);
      this.selectedDate.setDate(selectedDay);

      this.formControl.setValue(
        this.selectedDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      );
      this.onChange.emit(this.fieldData);
      this.formControl.updateValueAndValidity();
      this.formControl.markAsDirty();

      this.close();
    }

    if (this.decadeView) {
      const selectedYear = Number(
        (e.composedPath()[0] as HTMLElement).innerText.replace(/[^0-9]+/g, '')
      );

      this.current.setFullYear(selectedYear);

      const startMonth = getBeginningOfMonth(this.range.startDate);
      const endMonth = getBeginningOfMonth(this.range.endDate);
      const currentMonth = getBeginningOfMonth(this.current);

      if (currentMonth <= startMonth) {
        this.current.setMonth(startMonth.getMonth());
      } else if (currentMonth >= endMonth) {
        this.current.setMonth(endMonth.getMonth());
      }
      this.handleState('month');
    }

    if (this.yearView) {
      const selectedMonth = this.MONTHS.map((month) => month.slice(0, 3)).indexOf(
        (e.composedPath()[0] as HTMLElement).innerText
      );

      if (selectedMonth > -1) this.current.setMonth(selectedMonth);
      this.handleState('month');
    }
  }

  switchToDecade() {
    this.buildAdjacentYears();
    this.handleState('decade');
  }

  switchToYear() {
    this.handleState('year');
  }

  switchToMonth() {
    this.handleState('month');
  }

  public nextSet() {
    if (this.monthView) {
      this.current = getNextMonth(this.currentMonth, this.currentYear);
      this.buildWeeks();
    }

    if (this.yearView) {
      this.current.setFullYear(this.current.getFullYear() + 1);
      this.buildMonths();
    }

    if (this.decadeView) {
      this.current.setFullYear(this.current.getFullYear() + 9);
      this.buildAdjacentYears();
    }
  }

  public previousSet() {
    if (this.monthView) {
      this.current = getLastMonth(this.currentMonth, this.currentYear);
      this.buildWeeks();
    }

    if (this.yearView) {
      this.current.setFullYear(this.current.getFullYear() - 1);
      this.buildMonths();
    }

    if (this.decadeView) {
      this.current.setFullYear(this.current.getFullYear() - 9);
      this.buildAdjacentYears();
    }
  }

  returnMonthAndYearAsDate(year: number, month: number) {
    return new Date(year, month, 1);
  }

  moveToToday() {
    this.current = getDateWithoutTime();
    this.buildWeeks();
    this.buttonsToFocus.nativeElement.focus();
  }

  get currentMonth() {
    return this.current.getMonth();
  }

  get isCurrentMonth() {
    return this.current.getMonth() === new Date().getMonth();
  }

  get isCurrentYear() {
    return this.current.getFullYear() === new Date().getFullYear();
  }

  get currentYear() {
    return this.current.getFullYear();
  }

  get currentMonthName() {
    return this.MONTHS[this.current.getMonth()];
  }
}
