<fieldset [formGroup]="form">
  <legend [ngClass]="{ 'sr-only': fieldData.labelless }">{{ fieldData.label }}</legend>
  <ul class="h-list h-list--nowrap h-list--bare h-list--middle">
    <li
      class="radio-card radio-card__narrow radio-card__attached"
      *ngFor="let option of fieldData.options; index as i; trackBy: trackByValue"
    >
      <label for="{{ fieldData.id }}-{{ i }}" class="radio-card--inner">
        <input
          type="radio"
          id="{{ fieldData.id }}-{{ i }}"
          name="{{ fieldData.name }}"
          [attr.aria-describedby]="describedBy"
          (change)="onChange($event, option.value)"
          [value]="option.value"
          [checked]="option.checked"
          [required]="fieldData.required"
        />
        <span class="radio-card--inner--background"></span>
        <span class="radio-card--inner--text">{{ option.text }}</span>
        <span class="radio-card--inner--description">{{ option.description }}</span>
        <!-- TODO what is this spacer and do I need it? -->
        <span class="radio-card--inner--spacer"></span>
      </label>
    </li>
  </ul>
</fieldset>
