import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { createFocusTrap, FocusTrap } from 'focus-trap';
import { FormService } from '../page-builder/form-elements/form.service';
import { PageBuilderService } from '../page-builder/page-builder.service';

/*
Adapted for Angular from https://github.com/kin/dot-com/blob/master/app/javascript/components/shared/modal/modal-view-component.ts
*/
@Component({
  selector: 'kin-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [FormService, PageBuilderService],
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild('modal') modal: ElementRef;

  @Input() escapable = true;

  @Input() id: string;

  @Input() active = false;

  @Output() onOpen: EventEmitter<{ id: string }> = new EventEmitter<{ id: string }>();

  @Output() onClose: EventEmitter<{ id: string }> = new EventEmitter<{ id: string }>();

  observer: MutationObserver;

  focusTrap: FocusTrap = null;

  returnElement: HTMLElement = document.body;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.modal.nativeElement.addEventListener('keydown', (e) => this.handleKey(e));
  }

  ngOnDestroy() {
    this.modal.nativeElement.removeEventListener('keydown', (e) => this.handleKey(e));
  }

  public open() {
    document.body.classList.add('has-open-modal');

    this.createMutationObserver();

    this.makeActive(true);

    if (this.focusTrap === null) {
      this.focusTrap = createFocusTrap(this.elementRef.nativeElement);
    }

    requestAnimationFrame(() => this.focusTrap.activate());

    this.onOpen.emit({ id: this.id });
  }

  public close() {
    this.observer.disconnect();
    this.makeActive(false);

    this.onClose.emit({ id: this.id });

    this.focusTrap.deactivate();

    document.body.classList.remove('has-open-modal');
  }

  makeActive(active: boolean) {
    this.active = active;
  }

  createMutationObserver() {
    const callback = (mutationList) => {
      mutationList.forEach((mutation: MutationRecord) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if ((mutation.target as HTMLElement).classList.contains('modal-override-overflow')) {
            this.modal.nativeElement
              .querySelector('.k-modal__content__body')
              .classList.add('k-modal__content__body--allow-overflow');
          } else {
            this.modal.nativeElement
              .querySelector('.k-modal__content__body')
              .classList.remove('k-modal__content__body--allow-overflow');
          }
        }
      });
    };

    this.observer = new MutationObserver(callback);

    this.observer.observe(document.body, {
      attributes: true,
      childList: false,
      subtree: false,
    });
  }

  handleKey(e: KeyboardEvent) {
    if (e.key === 'Escape' && this.escapable) {
      this.close();
    }
  }
}
