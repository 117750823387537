<a href="#main" class="sr-only sr-visible-on-focus">Skip to main content</a>
<div
  ngkResizeObserver
  box="border-box"
  (onResize)="handleHeaderResize($event)"
  class="header-group">
  <kin-non-prod-banner *ngIf="!isProd"></kin-non-prod-banner>
  <kin-header></kin-header>
</div>
<kin-header-circle *ngIf="hasHeaderCircle"
  @fadeInOutOfExistence></kin-header-circle>
<kin-address-bar *ngIf="showAddressBar && address" [address]="address"></kin-address-bar>
<main id="main" #main>
  <kin-progress-bar label="quote progress" class="progress-bar--main"></kin-progress-bar>
  <kin-message></kin-message>
  <div class="route-animation-container"
    [@routeSlideInOut]="slideInOutAnimationState"
    [@routeFadeInOut]="fadeInOutAnimationState"
    @fadeInOutOfExistence>
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</main>
<kin-footer></kin-footer>
<kin-loader></kin-loader>
