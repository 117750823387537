import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HelpText } from '../../models/question';
import { FormService } from '../form.service';
import { BaseFieldAddonComponent } from '../base-field-addon.component';

type Size = 'small' | 'medium';

/**
 * @desc
 * Creates a small hidden information window with customizable content that can be toggled
 *
 * @example
 * ```html
 *  <kin-form-tooltip class="tooltip|tooltip--inline|any" size="small|medium" [isActive]="true|false" [isEscapable]="true|false" [form]="quoteCardForm" [helpText]="quoteCardFieldData.helpText"></kin-form-tooltip>
 * ```
 *
 * @param {object} HelpText - Provides the text, icon, and type tooltip|hint to be displayed
 * @param {string} id - Unique identifier for the specific instance of tooltip
 * @param {FormGroup} form - Associates this instance of tooltip with a specific FormGroup
 * @param {boolean} isActive - Controls if the tooltip will be visible by default - defaults to false
 * @param {boolean} isEscapable - Controls if the escape key will close the tooltip - defaults to true
 * @param {string} size 'small' | 'medium' -- This adjusts the vertical position relative to the trigger - defaults to medium
 *
 * @returns {FormTooltipComponent}
 */

/**
 * @todo - https://github.com/kin/ng-kin/issues/430 - The ID associated with tooltips is not always passed in & does not currently contain a default.
 * As it is an ID the default will need to be unique for each instance of the FormTooltipComponent on a page. Additional context below.
 */

@Component({
  selector: 'kin-form-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class FormTooltipComponent extends BaseFieldAddonComponent implements OnInit, OnDestroy {
  @ViewChild('tooltip') tooltip: ElementRef;

  @Input() helpText: HelpText;

  @Input() id: string;

  @Input() form: FormGroup;

  @Input() isActive = false;

  @Input() isEscapable = true;

  @Input() size: Size = 'medium';

  tooltipListener: Subscription;

  constructor(private formService: FormService, private renderer2: Renderer2) {
    super();
  }

  private unlistener: () => void;

  ngOnInit() {
    this.tooltipListener = this.formService.callerElementIdGetter().subscribe((id) => {
      /**
       * @todo: https://github.com/kin/ng-kin/issues/430 ID's are capable of being null and triggering the handleStacking method
       */
      // all the ids are emitted so we have these two branches for things we want to do when the id matches this tooltip's and when it doesn't match
      if (!!id && this.id === id) {
        this.handleStacking(id);
      }
      if (!!id && this.id !== id) {
        this.close();
      }
    });

    this.unlistener = this.renderer2.listen('document', 'keydown.escape', () => {
      if (this.isEscapable) {
        this.close();
      }
    });
  }

  ngOnDestroy() {
    this.tooltipListener.unsubscribe();
    this.unlistener();
  }

  /**
   * Toggle Tooltip Visibility
   */
  public toggle(e) {
    e.stopPropagation();
    if (this.isActive) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * Open Tooltip
   */

  public open() {
    this.makeActive(true);
    this.tooltip.nativeElement.focus();
    this.formService.emitCallerElementId(this.id);
  }

  /**
   * Close Tooltip
   */

  public close() {
    this.makeActive(false);
  }

  makeActive(active: boolean) {
    this.isActive = active;
  }

  handleStacking(id) {
    if (!id) return;
    const radioCards = document.querySelectorAll('.radio-card');
    const target = this.tooltip.nativeElement.closest('.radio-card');
    radioCards.forEach((card: HTMLElement) => {
      const updatedCard = card;
      updatedCard.style.zIndex = '0';
    });
    target.style.zIndex = '100';
  }
}
