import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

// This CanActivate guard (https://angular.io/api/router/CanActivate) protects the /quote/* route from
// someone who does *not* have a JWT token with which to authenticate the needed API requests.

@Injectable()
export class NullTokenGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.queryParamMap.get('token')) {
      // Watches for any route with ?token=
      this.authService.saveToken(route.queryParamMap.get('token'));
      return true;
    }
    return this.authService.retrieveToken() === null ? this.router.parseUrl('/start') : true;
  }
}
