import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { accountForUTC, getAgeInDays } from '../../../../utilities/date';

export const maxAgeDays = (days: number): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const selectedDate = accountForUTC(new Date(control.value));

    const ageInDays = getAgeInDays(selectedDate);

    return ageInDays <= days ? null : { maxAgeDays: true };
  };
};

@Directive({
  selector: '[kinMaxAgeDaysValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxAgeDaysDirective,
      multi: true,
    },
  ],
})
export class MaxAgeDaysDirective implements Validator {
  @Input('kinMaxAgeDaysValidator') value: number;

  validate(control: AbstractControl): ValidationErrors | null {
    return maxAgeDays(this.value)(control);
  }
}
