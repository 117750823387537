<label class="input-textbox email" for="{{ fieldData.id }}" [formGroup]="form">
  <input 
    class="input-textbox--input"
    [ngClass]="{'fs-exclude': fieldData.fullStoryExclude}"
    [required]="fieldData.required" 
    [formControlName]="fieldData.name"
    type="email"
    placeholder=" "
    [email]="true"
    [attr.aria-describedby]="describedBy"
    name="{{ fieldData.name }}"
    id="{{ fieldData.id }}"
    [attr.autocomplete]="fieldData.autocomplete">
  <span class="input-textbox--label">{{ fieldData.label }}</span>
</label>
