import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhone',
})
export class FormatPhonePipe implements PipeTransform {
  transform(phoneNumber: string, type: 'url' | 'paren' | 'dot' | 'dash' = 'dash'): string {
    const sanitizedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
    const croppedPhoneNumber = sanitizedPhoneNumber.substring(sanitizedPhoneNumber.length - 10);
    const areaCode = croppedPhoneNumber.substring(0, 3);
    const exchange = croppedPhoneNumber.substring(3, 6);
    const subscriber = croppedPhoneNumber.substring(6, 10);

    if (type === 'url') {
      return `tel:+1${croppedPhoneNumber}`;
    }
    if (type === 'paren') {
      return `(${areaCode}) ${[exchange, subscriber].join('-')}`;
    }
    if (type === 'dot') {
      return [areaCode, exchange, subscriber].join('.');
    }
    // dash
    return [areaCode, exchange, subscriber].join('-');
  }
}
