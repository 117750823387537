import { Component, Input } from '@angular/core';
import { BaseFieldAddonComponent } from '../base-field-addon.component';

@Component({
  selector: 'kin-form-async-validation-loader',
  templateUrl: './async-validation-loader.component.html',
  styleUrls: ['./async-validation-loader.component.scss'],
})
export class FormAsyncValidationLoaderComponent extends BaseFieldAddonComponent {
  @Input() status: string;

  @Input() fieldLabel: string;

  get valid() {
    return this.status === 'VALID';
  }

  get pending() {
    return this.status === 'PENDING';
  }

  get text() {
    if (this.valid) {
      return 'Verified!';
    }
    if (this.pending) {
      return this.fieldLabel
        ? `Verifying your ${this.fieldLabel.toLowerCase()}...`
        : 'Verifying...';
    }
    return '';
  }
}
