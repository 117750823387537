/* eslint-disable import/no-cycle */
import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  AsyncValidatorFn,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { dateValidator } from '../directives/validators/date.validator';
import { emailValidator } from '../directives/validators/email.validators';
import { maxAgeDays } from '../directives/validators/max-age-days.validator';
import { maxAgeYears } from '../directives/validators/max-age-years.validator';
import { maxValue } from '../directives/validators/max-value.validator';
import { minAgeDays } from '../directives/validators/min-age-days.validator';
import { minAgeYears } from '../directives/validators/min-age-years.validator';
import { minValue } from '../directives/validators/min-value.validator';
import { phoneValidator } from '../directives/validators/phone.validator';
import { everyoneApiValidator } from '../directives/validators/everyone-api.validator';
import { briteVerifyValidator } from '../directives/validators/brite-verify.validator';
import { Question } from '../models/question';
import { CheckboxQuestion } from '../models/form-elements/checkbox';
import { EmailQuestion } from '../models/form-elements/email';
import { InputQuestion } from '../models/form-elements/input';
import { NumberQuestion } from '../models/form-elements/number';
import { RadioQuestion } from '../models/form-elements/radio';
import { RequestService } from '../../../services/request.service';

type InputTypeQuestions = InputQuestion | NumberQuestion | EmailQuestion;

@Injectable()
export class FormService {
  callerElementId = new BehaviorSubject(null);

  constructor(public formBuilder: FormBuilder, private requestService: RequestService) {}

  emitCallerElementId(id: string): void {
    this.callerElementId.next(id);
  }

  callerElementIdGetter() {
    return this.callerElementId;
  }

  /**
   * The PageBuilderComponent begins by invoking prepareForm() with a list of Question
   * Any transformations to the Questions need to be done before inserted here.
   *
   * We set up a FormGroup with a corresponding FormControl for each question. Additionally,
   * for some question types, like radio and checkbox, we use the FormGroup object as a vessel
   * to hold an additional FormControl or FormGroup which we read from and write back to the main
   * FormControl. As an example, an `option` can be set to specify the behavior of an input element
   * like the checkbox (see the component for implementation specifics).
   *
   * @example
   * form = { // root FormGroup
   *   radio: {}, // radio FormControl we're storing on form for conveniece
   *   checkbox: {}, // checkbox FormGroup we're storing on form for conveniece
   *   controls: {}, // FormGroup.controls property - holds the source of truth FormControl objects which are read from on form submission
   * }
   */
  prepareForm(questions: Question[]): FormGroup {
    const formGroup = this.toFormGroup(questions);
    const radios = questions.filter(
      (q) => q.type === 'radio' || q.type === 'auto-radio' || q.type === 'attached-radio'
    );
    const checkboxes = questions.filter((q) => q.type === 'checkbox');
    const childrenQuestions = questions.filter((q) => q.children.length > 0);

    if (radios) {
      radios.forEach((radio: RadioQuestion) => {
        formGroup[radio.name] = this.prepareRadioQuestion(radio);

        // Verify any questions with values have their radio buttons selected.
        const checked = radio.options.find((option) => option.checked === true)?.value;
        formGroup.get(radio.name).patchValue(checked);
      });
    }

    if (checkboxes) {
      checkboxes.forEach((checkbox: CheckboxQuestion) => {
        formGroup[checkbox.name] = this.prepareCheckboxQuestion(checkbox);

        /**
         * Set initial checkbox FormControl value
         *
         * If single input, value will be a boolean derived from option.checked.
         *
         * If multiple inputs, value will be { [key: string]: boolean }
         * where key is derived from option.value and boolean is derived from option.checked.
         */
        if (checkbox.options.length === 1) {
          const checked = checkbox.options[0].checked ?? false;
          formGroup.get(checkbox.name).patchValue(checked);
          return;
        }

        const checked = checkbox.options.reduce(
          (valueObj, currentOption) => ({
            ...valueObj,
            ...{ [currentOption.value]: currentOption.checked ?? false },
          }),
          {}
        );
        formGroup.get(checkbox.name).patchValue(checked);
      });
    }

    if (childrenQuestions) {
      childrenQuestions.forEach((q) => this.walkChildren(formGroup, q));
    }

    return formGroup;
  }

  toFormGroup(questions: Question[]): FormGroup {
    const group: Record<string, FormControl> = {};

    questions.forEach((question) => {
      let formControl: FormControl;
      const initialBaseQuestionOptions = {
        disabled: question.disabled,
        value: question.value || '',
      };

      switch (question.type) {
        case 'date':
          formControl = new FormControl(
            { ...initialBaseQuestionOptions },
            this.attachInputValidators(question as InputQuestion, [dateValidator()]),
            this.attachAsyncInputValidators(question as InputQuestion)
          );
          break;
        case 'text':
        case 'number':
          formControl = new FormControl(
            { ...initialBaseQuestionOptions },
            this.attachInputValidators(question as InputQuestion),
            this.attachAsyncInputValidators(question as InputQuestion)
          );
          break;
        case 'tel':
          formControl = new FormControl(
            { ...initialBaseQuestionOptions },
            this.attachInputValidators(question as InputQuestion, [
              Validators.minLength(10),
              Validators.maxLength(14),
              phoneValidator(),
            ]),
            this.attachAsyncInputValidators(question as InputQuestion)
          );
          break;
        case 'email':
          formControl = new FormControl(
            { ...initialBaseQuestionOptions },
            this.attachInputValidators(question as InputQuestion, [emailValidator()]),
            this.attachAsyncInputValidators(question as InputQuestion)
          );
          break;
        default: {
          formControl = question.required
            ? new FormControl({ ...initialBaseQuestionOptions }, Validators.required)
            : new FormControl({ ...initialBaseQuestionOptions });
        }
      }

      // We want Angular to recognize that a field with a value is already fair game to validate / mark invalid.
      if (question.value) {
        formControl.markAsDirty();
        formControl.markAsTouched();
        formControl.updateValueAndValidity();
      }

      group[question.name] = formControl;
    });

    return this.formBuilder.group(group);
  }

  attachInputValidators(
    question: InputTypeQuestions,
    otherValidators?: ValidatorFn[]
  ): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (question.required) {
      validators.push(Validators.required);
    }

    if (question.validations?.minValue !== undefined) {
      validators.push(minValue(question.validations.minValue));
    }

    if (question.validations?.maxValue !== undefined) {
      validators.push(maxValue(question.validations.maxValue));
    }

    if (question.validations?.minLength !== undefined) {
      validators.push(Validators.minLength(question.validations.minLength));
    }

    if (question.validations?.maxLength !== undefined) {
      validators.push(Validators.maxLength(question.validations.maxLength));
    }

    if (question.validations?.minAgeDays !== undefined) {
      validators.push(minAgeDays(question.validations.minAgeDays));
    }

    if (question.validations?.maxAgeDays !== undefined) {
      validators.push(maxAgeDays(question.validations.maxAgeDays));
    }

    if (question.validations?.minAgeYears !== undefined) {
      validators.push(minAgeYears(question.validations.minAgeYears));
    }

    if (question.validations?.maxAgeYears !== undefined) {
      validators.push(maxAgeYears(question.validations.maxAgeYears));
    }

    return otherValidators ? validators.concat(otherValidators) : validators;
  }

  attachAsyncInputValidators(
    question: InputTypeQuestions,
    otherAsyncValidators?: AsyncValidatorFn[]
  ): AsyncValidatorFn[] | undefined {
    const asyncValidators: AsyncValidatorFn[] = [];

    if (question.validations?.externalApi === 'everyone_api') {
      asyncValidators.push(everyoneApiValidator(this.requestService));
    }

    if (question.validations?.externalApi === 'brite_verify') {
      asyncValidators.push(briteVerifyValidator(this.requestService));
    }

    if (
      asyncValidators.length === 0 &&
      (otherAsyncValidators === undefined || otherAsyncValidators.length === 0)
    )
      return undefined;

    return otherAsyncValidators ? asyncValidators.concat(otherAsyncValidators) : asyncValidators;
  }

  prepareRadioQuestion(question: RadioQuestion): FormControl {
    const validators = question.required ? Validators.required : null;
    return new FormControl({ value: question.value, disabled: question.disabled }, validators);
  }

  prepareCheckboxQuestion(question: CheckboxQuestion): FormGroup {
    return this.formBuilder.group(
      question.options.reduce(
        (controlsConfig, currentOption) => ({
          ...controlsConfig,
          ...{
            [currentOption.value]: new FormControl({
              value: currentOption.checked,
              disabled: currentOption.disabled,
            }),
          },
        }),
        {}
      )
    );
  }

  toControlArray(questions: Question[]) {
    return questions.map((q) => {
      if (q.type === 'radio' || q.type === 'attached-radio') {
        return {
          name: q.name,
          control: this.prepareRadioQuestion(q as RadioQuestion),
        };
      }

      if (q.type === 'checkbox') {
        // TODO revisit to decide if checkbox beed required validator
        return {
          name: q.name,
          control: this.prepareCheckboxQuestion(q as CheckboxQuestion),
        };
      }

      return {
        name: q.name,
        control: new FormControl(q.value || '', this.attachInputValidators(q as InputQuestion)),
      };
    });
  }

  // This is used to verify children questions exist, whether to show them or hide them.
  // This is run on each change of the form.
  walkChildren(form: FormGroup, questionToEvaluate: Question) {
    const updatedQuestionToEvaluate = questionToEvaluate;
    const questions = updatedQuestionToEvaluate.children.filter((child) =>
      this.findValue(form.get(updatedQuestionToEvaluate.name).value, child.eligibility?.value)
    );

    if (questions.length > 0) {
      updatedQuestionToEvaluate.hasEligibleChildren = true;
      questions.forEach((field) => {
        const updatedField = field;
        updatedField.eligibility.hidden = false;
      });

      // Create our form controls and add them to the form.
      this.toControlArray(questions).forEach((child) => form.addControl(child.name, child.control));
    } else {
      updatedQuestionToEvaluate.hasEligibleChildren = false;
      updatedQuestionToEvaluate.children.forEach((child) => {
        const updatedChild = child;
        form.get(child.name)?.patchValue('');
        updatedChild.eligibility.hidden = true;
      });

      // Remove the controls from the form
      updatedQuestionToEvaluate.children
        .map((child) => child.name)
        .forEach((toRemove) => form.removeControl(toRemove));
    }
  }

  private findValue(parentValue: any, childValue: any) {
    if (parentValue === null) {
      return undefined;
    }

    if (parentValue instanceof Array) {
      return parentValue.includes(childValue);
    }

    if (childValue instanceof String) {
      return parentValue === childValue;
    }

    return parentValue === childValue;
  }
}
