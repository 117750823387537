<div class="datepicker__container" [formGroup]="form">
  <label class="input-textbox" [for]="fieldData.id" [id]="fieldData.id + '__label'">
    <input
      #textbox
      type="text"
      [id]="fieldData.id"
      [name]="fieldData.name"
      [kinMask]="fieldData.mask || '00/00/0000'"
      [value]="fieldData.value"
      minlength="10"
      maxlength="10"
      pattern="[0-9\/]*"
      [formControlName]="fieldData.name"
      placeholder="mm/dd/yyyy"
      inputmode="decimal"
      class="input-textbox--input"
      [attr.aria-describedby]="describedBy"
      [required]="fieldData.required"
    />
    <span class="input-textbox--label">{{fieldData.label}}</span>
  </label>
  <div *ngIf="fieldData.datepicker" (ngkClickOutside)="isActive = false">
    <button 
      class="datepicker__container__trigger" 
      type="button"
      #trigger
      (click)="toggle()"
      [disabled]="fieldData.disabled"
      [attr.aria-controls]="fieldData.id + '__picker'">
      <!-- TODO: Move this into an image file at some point-->
      <svg class="icon" width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false">
        <path d="M22.9256 3.53852H19.2254V2.40579C19.2254 2.02822 18.8982 1.70099 18.5206 1.70099C18.143 1.70099 17.8158 2.02822 17.8158 2.40579V3.56369H9.25742V2.40579C9.25742 2.02822 8.93019 1.70099 8.55262 1.70099C8.17504 1.70099 7.84781 2.02822 7.84781 2.40579V3.56369H4.14758C2.7128 3.56369 1.58008 4.72158 1.58008 6.13119V21.1335C1.58008 22.5683 2.73797 23.701 4.14758 23.701H22.9004C24.3352 23.701 25.468 22.5431 25.468 21.1335V6.13119C25.4931 4.69641 24.3352 3.53852 22.9256 3.53852ZM4.14758 4.94813H7.84781V6.10602C7.84781 6.4836 8.17504 6.81083 8.55262 6.81083C8.93019 6.81083 9.25742 6.4836 9.25742 6.10602V4.94813H17.8158V6.10602C17.8158 6.4836 18.143 6.81083 18.5206 6.81083C18.8982 6.81083 19.2254 6.4836 19.2254 6.10602V4.94813H22.9256C23.5801 4.94813 24.1087 5.47673 24.1087 6.13119V8.77422H2.98969V6.13119C2.98969 5.47673 3.51829 4.94813 4.14758 4.94813ZM22.9256 22.2914H4.14758C3.49312 22.2914 2.96452 21.7628 2.96452 21.1083V10.1838H24.0583V21.1335C24.0835 21.7628 23.5549 22.2914 22.9256 22.2914Z" fill="#242221"/>
        <path d="M14.4427 17.7856H12.6052C12.2276 17.7856 11.9004 18.1129 11.9004 18.4905C11.9004 18.868 12.2276 19.1953 12.6052 19.1953H14.4427C14.8203 19.1953 15.1475 18.868 15.1475 18.4905C15.1475 18.1129 14.8455 17.7856 14.4427 17.7856Z" fill="#242221"/>
        <path d="M20.2328 17.7856H18.3952C18.0177 17.7856 17.6904 18.1129 17.6904 18.4905C17.6904 18.868 18.0177 19.1953 18.3952 19.1953H20.2328C20.6103 19.1953 20.9376 18.868 20.9376 18.4905C20.9376 18.1129 20.6355 17.7856 20.2328 17.7856Z" fill="#242221"/>
        <path d="M8.55232 17.7856H6.73996C6.36239 17.7856 6.03516 18.1129 6.03516 18.4905C6.03516 18.868 6.36239 19.1953 6.73996 19.1953H8.57749C8.95506 19.1953 9.28229 18.868 9.28229 18.4905C9.28229 18.1129 8.95506 17.7856 8.55232 17.7856Z" fill="#242221"/>
        <path d="M14.4427 13.28H12.6052C12.2276 13.28 11.9004 13.6072 11.9004 13.9848C11.9004 14.3623 12.2276 14.6896 12.6052 14.6896H14.4427C14.8203 14.6896 15.1475 14.3623 15.1475 13.9848C15.1475 13.582 14.8455 13.28 14.4427 13.28Z" fill="#242221"/>
        <path d="M8.55232 13.28H6.73996C6.36239 13.28 6.03516 13.6072 6.03516 13.9848C6.03516 14.3623 6.36239 14.6896 6.73996 14.6896H8.57749C8.95506 14.6896 9.28229 14.3623 9.28229 13.9848C9.25712 13.582 8.95506 13.28 8.55232 13.28Z" fill="#242221"/>
        <path d="M18.521 14.6896H20.3333C20.7109 14.6896 21.0382 14.3623 21.0382 13.9848C21.0382 13.6072 20.7109 13.28 20.3333 13.28H18.4958C18.1182 13.28 17.791 13.6072 17.791 13.9848C17.8162 14.3623 18.1182 14.6896 18.521 14.6896Z" fill="#242221"/>
      </svg>
      <span class="sr-only">Open Datepicker</span>
    </button>
    <div
      #datepicker
      class="k-datepicker"
      [ngClass]="{'is-active': isActive}"
      id="{{fieldData.id}}__picker"
      tabindex="-1"
      aria-label="Datepicker"
      [attr.aria-expanded]="isActive"
    >
      <div class="k-datepicker__header">
        <div class="split">
          <div class="split--left">
            <div class="h-list h-list--x2 h-list--middle" #buttonsToFocus>
              <button class="k-datepicker__header__toggle" *ngIf="monthView" type="button" (click)="switchToYear()">
                <span class="sr-only">View All Months in {{currentYear}}</span>
                <span class="h-list h-list--bare h-list--nowrap h-list--middle">
                  <span>{{currentMonthName}}</span>
                  <svg class="n-icon" width="16" height="16" viewBox="0 0 24 24" fill="#167c80" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.25635 8.81195L12.2563 14.812L18.2563 8.81195"
                      stroke="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>
              </button>
              <button class="k-datepicker__header__toggle" *ngIf="yearView" type="button" (click)="switchToMonth()">
                <span class="sr-only">Switch to Month View of </span>
                <span class="h-list h-list--bare h-list--nowrap h-list--middle">
                  <span>{{current.getFullYear()}}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="#167c80"
                    stroke="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="18 15 12 9 6 15" />
                  </svg>
                </span>
              </button>
              <button class="k-datepicker__header__toggle" *ngIf="monthView" type="button" (click)="switchToDecade()">
                <span class="sr-only">View Years Adjacent to </span>
                <span class="h-list h-list--bare h-list--nowrap h-list--middle">
                  <span>{{current.getFullYear()}}</span>
                  <svg class="n-icon" width="16" height="16" viewBox="0 0 24 24" fill="#167c80" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.25635 8.81195L12.2563 14.812L18.2563 8.81195"
                      stroke="none"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>
              </button>
              <button class="k-datepicker__header__toggle" *ngIf="decadeView" type="button" (click)="switchToMonth()">
                <span class="sr-only">Switch back to Month View</span>
                <span class="h-list h-list--bare h-list--nowrap h-list--middle">
                  <span>{{years[0][0].year}} - {{years[2][2].year}}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="#167c80"
                    stroke="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="18 15 12 9 6 15" />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div class="split--right">
            <div class="h-list h-list--bare h-list--right h-list--middle">
              <div>
                <button class="k-datepicker__header__button" (click)="previousSet()" type="button">
                  <span class="sr-only">
                    Previous {{monthView ? 'Month' : '' }} {{yearView ? "Year" : ""}} {{decadeView ? "Decade" : ""}}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    stroke-width=".8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="15 18 9 12 15 6"></polyline>
                  </svg>
                </button>
              </div>
              <div>
                <button class="k-datepicker__header__button" (click)="nextSet()" type="button">
                  <span class="sr-only"
                    >Next {{monthView ? 'Month' : '' }} {{yearView ? "Year" : ""}} {{decadeView ? "Decade" : ""}}
                  </span>
                  <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 18L15 12L9 6" stroke="#000" stroke-width=".8" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="k-datepicker__body">
        <table *ngIf="monthView" class="k-datepicker__body__table">
          <caption class="sr-only">
            {{ currentMonthName }} {{ currentYear}}
          </caption>
          <thead>
            <tr>
              <th aria-hidden="true" *ngFor="let day of DAYS_OF_WEEK_ABBREVIATED;">{{day}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let weeks of days; let i = index;">
              <td *ngFor="let date of weeks">
                <button 
                  *ngIf="date.active && inRange(date.date)" 
                  (click)="onDateSelect($event)" 
                  [ngClass]="{
                    'selected': selectedDate && compareDay(date.date, selectedDate),
                    'today': compareDay(date.date, today)
                  }" 
                  type="button" 
                  [attr.aria-selected]="selectedDate && compareDay(date.date, selectedDate)"
                >
                  <span class="sr-only">{{currentMonthName}}</span> {{date.day}}
                  <span class="sr-only" *ngIf="selectedDate && compareDay(date.date, selectedDate)">selected</span>
                </button>
    
                <button *ngIf="!date.active || !inRange(date.date)" class="in-active" disabled aria-hidden="true">
                  <span class="sr-only">{{currentMonthName}}</span> {{date.day}}
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="!(isCurrentMonth && isCurrentYear)">
            <tr>
              <td colspan="7">
                <button type="button" (click)="moveToToday()"><span>Go to Today</span></button>
              </td>
            </tr>
          </tfoot>
        </table>
        <table *ngIf="decadeView" class="k-datepicker__body__table k-datepicker__body__table--decade">
          <caption></caption>
          <tbody>
            <tr *ngFor="let yearList of years; let i = index;">
              <td *ngFor="let year of yearList;">
                <button [ngClass]="{'selected': year.year === current.getFullYear(), 'in-active': !inRange(year.date)}" [attr.aria-selected]="year.year === current.getFullYear()" (click)="onDateSelect($event)" type="button" [disabled]="!inRange(year.date)">
                  {{ year.year }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <table *ngIf="yearView" class="k-datepicker__body__table k-datepicker__body__table--month">
          <caption></caption>
          <tbody>
            <tr *ngFor="let monthList of months; let i = index;">
              <td *ngFor="let month of monthList;">
                <button [ngClass]="{'selected': month.month === current.getMonth(), 'in-active': !inRange(returnMonthAndYearAsDate(month.year, month.month))}" [attr.aria-selected]="month.month === current.getMonth()" (click)="onDateSelect($event)" type="button" [disabled]="!inRange(returnMonthAndYearAsDate(month.year, month.month))">
                  <span class="sr-only">{{MONTHS[month.month]}}</span>
                  <span aria-hidden="true">{{MONTHS[month.month].slice(0, 3)}}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
