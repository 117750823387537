import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule, FormRadioModule } from 'lib-kinponents';
import { IntersectionObserverModule } from '@ng-web-apis/intersection-observer';
import { FormAutocompleteComponent } from './form-elements/autocomplete/autocomplete.component';
import { PageBuilderComponent } from './page-builder.component';
import { FormAddressComponent } from './form-elements/address/address.component';
import { FormAutoRadioComponent } from './form-elements/auto-radio/auto-radio.component';
import { FormTextComponent } from './form-elements/text/text.component';
import { FormSelectComponent } from './form-elements/select/select.component';
import { FormCheckboxComponent } from './form-elements/checkbox/checkbox.component';
import { FormRadioComponent } from './form-elements/radio/radio.component';
import { FormAttachedRadioComponent } from './form-elements/attached-radio/attached-radio.component';
import { FormEmailComponent } from './form-elements/email/email.component';
import { FormNumberComponent } from './form-elements/number/number.component';
import { FormFieldComponent } from './form-elements/field/field.component';
import { FormTelComponent } from './form-elements/tel/tel.component';
import { FormDatePickerComponent } from './form-elements/datepicker/datepicker.component';
import { MaskDirective } from './directives/mask.directive';
import { PageElementComponent } from './page-elements/page-element/page-element.component';
import { ParagraphElementComponent } from './page-elements/paragraph/paragraph.component';
import { TestimonialElementComponent } from './page-elements/testimonial/testimonial.component';
import { SharedModule } from '../../shared.module';
import { FormTooltipComponent } from './form-elements/tooltip/tooltip.component';
import { FormHintComponent } from './form-elements/hint/hint.component';
import { FormAsyncValidationLoaderComponent } from './form-elements/async-validation-loader/async-validation-loader.component';
import { FormToggleComponent } from './form-elements/toggle/toggle.component';
import { FormHiddenComponent } from './form-elements/hidden/hidden.component';
import { AccordionComponent } from './page-elements/accordion/accordion.component';
import { QuoteSummaryCardComponent } from '../quote-summary-card/quote-summary-card.component';
import { RoofDepreciationModalComponent } from '../roof-depreciation-modal/roof-depreciation-modal.component';
import { FormRemoteAutocompleteComponent } from './form-elements/remote-autocomplete/remote-autocomplete.component';
import { HighlightTextPipe } from './pipes/highlight-text.pipe';
import { AutocompleteControlValueAccessorDirective } from './form-elements/autocomplete/autocomplete-control-value-accessor.directive';
import { ToRadioControlOptionPipe } from './pipes/to-radio-control-option.pipe';
import '@kin/web-components/dist/kin-accordion/kin-accordion';

@NgModule({
  declarations: [
    FormAddressComponent,
    FormAutocompleteComponent,
    FormAutoRadioComponent,
    PageBuilderComponent,
    FormTextComponent,
    FormSelectComponent,
    FormCheckboxComponent,
    FormToggleComponent,
    FormRadioComponent,
    FormAttachedRadioComponent,
    FormEmailComponent,
    FormNumberComponent,
    FormFieldComponent,
    FormTelComponent,
    FormDatePickerComponent,
    FormTooltipComponent,
    FormHintComponent,
    FormAsyncValidationLoaderComponent,
    MaskDirective,
    PageElementComponent,
    ParagraphElementComponent,
    TestimonialElementComponent,
    FormHiddenComponent,
    AccordionComponent,
    QuoteSummaryCardComponent,
    RoofDepreciationModalComponent,
    RoofDepreciationModalComponent,
    FormRemoteAutocompleteComponent,
    HighlightTextPipe,
    AutocompleteControlValueAccessorDirective,
    ToRadioControlOptionPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    ClickOutsideModule,
    FormRadioModule,
    IntersectionObserverModule,
  ],
  exports: [
    PageBuilderComponent,
    FormAddressComponent,
    FormAutocompleteComponent,
    FormAutoRadioComponent,
    PageBuilderComponent,
    FormTextComponent,
    FormSelectComponent,
    FormCheckboxComponent,
    FormRadioComponent,
    FormAttachedRadioComponent,
    FormEmailComponent,
    FormNumberComponent,
    FormFieldComponent,
    FormTelComponent,
    FormToggleComponent,
    FormDatePickerComponent,
    FormTooltipComponent,
    FormHintComponent,
    FormAsyncValidationLoaderComponent,
    MaskDirective,
    PageElementComponent,
    ParagraphElementComponent,
    TestimonialElementComponent,
    AccordionComponent,
    QuoteSummaryCardComponent,
    RoofDepreciationModalComponent,
    RoofDepreciationModalComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PageBuilderModule {}
