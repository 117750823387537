<ng-container [ngSwitch]="elementData.type">
  <!-- TODO: Remove $any if someday type narrowing works with ngSwitch -->
  <kin-page-paragraph *ngSwitchCase="'paragraph'" [elementData]="$any(elementData)"></kin-page-paragraph>
  <kin-page-testimonial *ngSwitchCase="'testimonial'" [elementData]="$any(elementData)"></kin-page-testimonial>
</ng-container>
<!-- <ng-container *ngIf="element.hasEligibleChildren">
  <div class="v-list" style="margin-left: 1rem; margin-top: 1rem; border-radius: 8px;">
    <div *ngFor="let child of element.children; let i = index;">
      <kin-page-element [element]="child"></kin-page-element>
    </div>
  </div>
</ng-container> -->
