/**
 * Details for setCookieConfig used by the setCookie() method
 */
interface SetCookieConfig {
  cookieName: string; // key used to set the cookie
  cookieValue: string; // value of the cookie
  cookieDomain?: string; // domain the cookie should be set on
  daysActive?: number; // how many days the cookie is active, sets the expiration date on the cookie
  path?: string; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#path_attribute
  sameSite?: string; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
  cookieHostname?: string; // defaults to window.location.hostname
}

/**
 * Details for deleteCookieConfig used by the deleteCookie() method
 */
interface DeleteCookieConfig {
  cookieName: string; // key used to set the cookie
  cookieDomain?: string; // domain the cookie should be set on
  path?: string; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#path_attribute
  sameSite?: string; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
}

/**
 * Cookie Helper Class (TypeScript Kin Class - TsK)
 * - includes general cookie unities and kin specific details
 * - ported from: https://github.com/kin/marketing-website/tree/main/themes/kin-2021/assets/js/utils/cookie-helper
 */
export class TsKCookieHelper {
  /**
   * Get Cookie List
   * @returns array of all available cookies
   */
  public static getAllCookies(): { [key: string]: string }[] {
    if (document.cookie === '') {
      return [];
    }
    return document.cookie.split('; ').map((cookie) => {
      const [key, value] = cookie.split('=');
      return { key, value };
    });
  }

  /**
   * Get Cookie
   * @param key = the cookie key or name
   * @returns value of the cookie
   */
  public static getCookie(key: string): string | undefined {
    return this.getAllCookies().find((cookie) => cookie.key === key)?.value;
  }

  /**
   * Set Cookie
   * @param {Object} setCookieConfig - required config for creating a cookie
   * @returns true = success, false = failed
   */
  public static setCookie({
    cookieName,
    cookieValue,
    cookieDomain,
    daysActive = 60,
    path = '/', // Path=/: matches any requested URL under the set domain
    sameSite = 'SameSite=Strict; Secure', // "SameSite=Strict": will not provide to third party websites. "Secure": restricts to only https connections
    cookieHostname,
  }: SetCookieConfig) {
    const domain: string = cookieDomain ?? this.getCookieDomain(cookieHostname);
    const date = new Date();
    const futureDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + daysActive);
    document.cookie = `${cookieName}=${cookieValue}; Domain=${domain}; Expires=${futureDate.toUTCString()}; Path=${path}; ${sameSite}`;
    return true;
  }

  /**
   * Delete Cookie
   * @param {Object} deleteCookieConfig - required config for deleting a cookie
   * @returns true = success, false = failed
   */
  public static deleteCookie({
    cookieName,
    cookieDomain,
    path = '/', // default used in setCookie
    sameSite = 'SameSite=Strict; Secure', // default used in setCookie
  }: DeleteCookieConfig) {
    if (this.getCookie(cookieName)) {
      this.setCookie({
        cookieName,
        cookieValue: '',
        daysActive: -100,
        sameSite,
        path,
        cookieDomain,
      });
    }
  }

  /**
   * Gets cookie domain
   * - defaults to production (kin.com)
   * - if root domain is used, also applies to subdomains: https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#domain_attribute
   * @returns cookie domain string
   */
  private static getCookieDomain(hostname = window.location.hostname): string {
    if (hostname.includes('localhost')) {
      return 'localhost';
    }
    if (hostname.includes('kin.com')) {
      // run this before .co so we don't confused it with .com
      return 'kin.com';
    }
    if (hostname.includes('kin.co')) {
      return 'kin.co';
    }
    if (hostname.includes('kin.haus')) {
      return 'kin.haus';
    }
    if (hostname.includes('kin.insure')) {
      return 'kin.insure';
    }
    return 'kin.com';
  }
}
