import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from 'lib-kinponents';
import { SharedModule } from '../shared/shared.module';
import { ResumeMyQuoteComponent } from './resume-my-quote.component';
import { PageBuilderModule } from '../shared/components/page-builder/page-builder.module';
import { AnimationsModule } from '../shared/animations/animations.module';
import { TrackingService } from '../shared/services/tracking.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    PageBuilderModule,
    AnimationsModule,
    PipesModule,
  ],
  declarations: [ResumeMyQuoteComponent],
  exports: [ResumeMyQuoteComponent],
  providers: [TrackingService],
})
export class ResumeMyQuoteModule {}
