import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToggleQuestion } from '../../models/form-elements/toggle';
import { BaseFieldComponent } from '../base-field.component';

@Component({
  selector: 'kin-form-toggle',
  templateUrl: './toggle.component.html',
})
export class FormToggleComponent extends BaseFieldComponent<ToggleQuestion> {
  @Input() fieldData: ToggleQuestion;

  @Input() form: FormGroup;

  onChange(e: any) {
    // TODO is it breaking pattern here not be using FormControl here?
    this.form.get(this.fieldData.name).setValue(e.target.checked);
  }

  normalizeToggleLabel(label: string): string {
    const labelAllowList = ['on', 'off', 'yes', 'no'];
    return labelAllowList.includes(label.toLowerCase()) ? label : null;
  }

  get toggleLabel() {
    const { name, options } = this.fieldData;
    const onValue = this.normalizeToggleLabel(options.find((option) => option.value)?.text) ?? 'on';
    const offValue =
      this.normalizeToggleLabel(options.find((option) => !option.value)?.text) ?? 'off';
    return this.form.get(name).value ? onValue : offValue;
  }
}
