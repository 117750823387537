import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../../services/auth.service';

export interface ProgressBar {
  name: string;
  steps: {
    label: string;
    pages: string[];
  }[];
}

@Component({
  selector: 'kin-progress-bar',
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  @Input() label: string;

  progressBarConfig: ProgressBar[] = [];

  steps = [];

  max = 1;

  value = 0;

  startSegment = 'start';

  loadingSegment = 'loading';

  showSegments = ['start', 'quote'];

  hideSegments = ['compare', 'payment', 'review'];

  shouldShow = true;

  configSubscription: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event: RouterEvent) => this.handleRouterSubscription(event));
    this.configSubscription = this.authService.configSubscription.subscribe((data) => {
      if (data?.progress_bar) {
        // Deep clone config to support multiple instances of component.
        this.progressBarConfig = JSON.parse(JSON.stringify(data?.progress_bar));
        this.initProgressBar();
        this.calculateMax();
      }
    });
  }

  ngOnDestroy() {
    this.configSubscription.unsubscribe();
  }

  initProgressBar() {
    this.progressBarConfig[0].steps.push({ label: 'Get Your Quote', pages: [] }); // adds last step which isn't sent in response
    this.steps = this.progressBarConfig[0].steps;
    this.changeDetectorRef.markForCheck();
  }

  handleRouterSubscription(event: RouterEvent) {
    if (event instanceof NavigationEnd && this.progressBarConfig) {
      this.calculateMax();
      this.calculateValue(event.url);
    }
    this.changeDetectorRef.markForCheck();
  }

  calculateMax() {
    const progressBar = this.progressBarConfig[0];
    this.max = progressBar == null ? undefined : progressBar.steps.length - 1;
  }

  calculateValue(navigationURL: string) {
    const currentProgressBar = this.progressBarConfig[0]; // TODO do this better when we implement multiple bars
    const navigationPathSegments: string[] = navigationURL.split('/');
    const sanitizedNavigationURL = navigationURL.replace(/^\/|\/$/g, ''); // remove leading and trailing slashes
    const currentPageHasNoShowSegments = !this.showSegments.some((segment) =>
      navigationPathSegments.includes(segment)
    );
    const currentPageHasHideSegment = this.hideSegments.some((segment) =>
      navigationPathSegments.includes(segment)
    );
    // TODO in the future we may want a more sophisticated approach to checking the URL for the first entry, eg, history length?
    if (
      !currentProgressBar ||
      ((currentPageHasNoShowSegments || currentPageHasHideSegment) && navigationURL !== '/')
    ) {
      // hide bar if no config, or
      // if none of the showSegments, ie, path keywords, are in the new URL then hide progress bar and return
      this.shouldShow = false;
      this.changeDetectorRef.markForCheck();
      return;
    }
    this.shouldShow = true;
    this.changeDetectorRef.markForCheck();

    if (navigationPathSegments.includes(this.loadingSegment)) return; // return early when on loading page without changing progress bar
    if (navigationPathSegments.includes(this.startSegment)) {
      // reset value if back to start path
      this.value = 0;
      return;
    }
    const stepInteger =
      currentProgressBar.steps.findIndex((step) => step.pages.includes(sanitizedNavigationURL)) ||
      Math.floor(this.value); // remove leading and trailing slashes
    /**
     * Disabling no-unsafe-optional-chaining for now since this logic depends on operations evaluating to NaN
     */
    const stepDecimal =
      // eslint-disable-next-line no-unsafe-optional-chaining
      (currentProgressBar.steps[stepInteger]?.pages.findIndex(
        (page) => page === sanitizedNavigationURL
      ) +
        1) /
      // eslint-disable-next-line no-unsafe-optional-chaining
      (currentProgressBar.steps[stepInteger]?.pages.length + 1);
    const generalDecimalForStep =
      // eslint-disable-next-line no-unsafe-optional-chaining
      1 / (currentProgressBar.steps[stepInteger]?.pages.length + 1) || 0.05; // defaulting this to be a small decimal in case current URL isn't specified in steps
    const stepValue = stepDecimal ? stepInteger + stepDecimal : this.value + generalDecimalForStep; // handles incremental step if current URL isn't specified in steps
    this.value = stepValue > this.value ? stepValue : this.value; // we don't want progress bar to go down
  }
}
