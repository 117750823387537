import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { accountForUTC, getDifferenceInYears } from '../../../../utilities/date';

export function maxAgeYears(years: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const today = accountForUTC(new Date(), true);
    const selectedDate = accountForUTC(new Date(control.value));
    const yearDifference = getDifferenceInYears(today, selectedDate);

    return yearDifference <= years ? null : { maxAgeYears: true };
  };
}

@Directive({
  selector: '[kinMaxAgeYears]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: maxAgeYears,
      multi: true,
    },
  ],
})
export class MaxAgeYearsDirective implements Validator {
  @Input('kinMaxAgeYears') value: number;

  validate(control: AbstractControl): ValidationErrors | null {
    return maxAgeYears(this.value)(control);
  }
}
