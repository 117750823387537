import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';

export function minValue(min: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value >= min ? null : { minValue: true };
  };
}

@Directive({
  selector: '[kinMinValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: minValue,
      multi: true,
    },
  ],
})
export class MinValueDirective implements Validator {
  @Input('kinMinValue') value: number;

  validate(control: AbstractControl): ValidationErrors | null {
    return minValue(this.value)(control);
  }
}
