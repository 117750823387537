import { Component, Input } from '@angular/core';
import { SpriteMapName } from 'projects/lib-kinponents/src/lib/types/sprite-map-name';

@Component({
  selector: 'ngk-svg[name]',
  styleUrls: ['./svg.component.scss'],
  templateUrl: './svg.component.html',
})
export class SvgComponent {
  @Input() name!: SpriteMapName;

  @Input() classes?: string;

  constructor() {}
}
