import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { BeginComponent } from './begin/begin.component';
import { NullTokenGuard } from './shared/guards/null-token.guard';
import { DevEnvGuard } from './shared/guards/dev-env.guard';
import { KinRoutes } from './shared/utilities/kin-routes';
import { ResumeMyQuoteComponent } from './resume-my-quote/resume-my-quote.component';
// Learn more about Routes and Lazy-loading Modules here: https://angular.io/guide/lazy-loading-ngmodules

const routes: KinRoutes = [
  { path: 'begin', component: BeginComponent, canActivate: [NullTokenGuard] },
  { path: '', redirectTo: 'start', pathMatch: 'full' }, // This defaults the Start page (with the address component)
  {
    path: '',
    children: [
      {
        path: 'resume',
        loadChildren: () => import('./resume/resume.module').then((m) => m.ResumeModule),
      },
      {
        path: 'start',
        loadChildren: () => import('./start/start.module').then((m) => m.StartModule),
      },
      {
        path: 'thanks',
        loadChildren: () => import('./thanks/thanks.module').then((m) => m.ThanksModule),
        canDeactivate: [false],
      }, // No going back!
      {
        path: 'quote',
        canActivate: [NullTokenGuard],
        loadChildren: () => import('./questions/questions.module').then((m) => m.QuestionsModule),
      },
    ],
  },
  { path: 'resume-my-quote', component: ResumeMyQuoteComponent },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'sandbox',
    loadChildren: () => import('./sandbox/sandbox.module').then((m) => m.SandboxModule),
    canActivate: [DevEnvGuard],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
