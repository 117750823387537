import { NgkLoggerDataDogConfig } from 'lib-kinponents';

export const loggerConfig: NgkLoggerDataDogConfig = {
  applicationId: '4821645f-2fa8-4d40-b8f0-1539307e8689',
  clientToken: 'pubf4f07a06c4c248fa80a14ddf197a8591',
  service: 'acquisition-funnel',
  activeUrls: [
    'welcome.kin.com', // needs to be first so we do not group everything under welcome.kin.com
    'staging.welcome.kin.com',
    'ra1.welcome.kin.com', // need to add dev so we do not group everything under welcome.kin.com
    'ra2.welcome.kin.com', // need to add dev so we do not group everything under welcome.kin.com
  ],
  sessionReplaySampleRate: 100,
};
