import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { AriaHelper } from '../../../utils/aria-helper';
import { FieldsetGridComponent } from '../../fieldset-grid/fieldset-grid.component';
import {
  ControlImageConfig,
  ControlOptionObject,
  ControlTooltipConfig,
} from '../../../types/control-option';
import { BaseFormComponent } from '../base-form-component';

/**
 * An extension of ControlOptionObject with properties to configure an image
 * @todo implement support for help text tooltip
 *
 * note: this is exported so other components can extend this for custom implementations
 */
export interface RadioControlOptionObject extends ControlOptionObject {
  image?: ControlImageConfig<'sprite'>;
  tooltip?: ControlTooltipConfig;
}
export type RadioControlOption = RadioControlOptionObject | string;

/**
 * This component is used to render a grid of radio buttons styled as cards in forms
 * @example
 * ``` html
 * <ngk-form-radio [options]="["Yes", "No"]" theme="card"></ngk-form-select>
 * ```
 * Note: Missing `options` or `theme` will result in compilation errors.
 */
@Component({
  selector: 'ngk-form-radio[options][theme]',
  templateUrl: './form-radio.component.html',
  styleUrls: ['./form-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRadioComponent<ThemeType extends 'card' | 'native' | 'button'>
  extends BaseFormComponent
  implements OnInit, OnDestroy
{
  protected _name?: string;

  /**
   * Sets the visual theme of the radio buttons. Required.
   * Available options are 'card', 'native' and 'button'.
   */
  @Input() theme!: ThemeType;

  /**
   * Radio input options. Required. Can be a list of strings, or objects with a value and label.
   */
  @Input() options!: (
    | (ThemeType extends 'native' ? ControlOptionObject : RadioControlOptionObject)
    | string
  )[];

  /**
   * ID of fieldset element. Radio buttons use this ID, with the label appended to it. If not set, one will be generated.
   * @example
   * //<ngk-form-radio [id]="test" [options]="["Yes"]"></ngk-form-select>,
   * <fieldset id="test-id"> <input id="test-Yes" /> </fieldset>
   */
  @Input() id: string = AriaHelper.getGeneratedId('ngk-form-radio');

  /**
   * Name for all radio buttons. Optional. If not set, it will be the same as the ID.
   */
  @Input() set name(value: string | undefined) {
    this._name = value;
  }

  get name(): string {
    if (this._name == null) {
      return this.id;
    }
    return this._name;
  }

  /**
   * Sets the Angular Reactive Forms FormControl. If one is not provided, one will be created.
   */
  @Input() control: FormControl = new FormControl(null);

  // TODO: Uncomment when tooltip/helptext is broken out
  // @Input() helpText?: HelpText;

  /**
   * Show or hide validation messages based off FormControl.errors, defaults to true. Intended for compatibility with app-funnel.
   */
  @Input() showValidationMessages: boolean = true;

  /**
   * Visually hidden by default, sets the text of the fieldset legend.
   * Highly recommended this is set for accessibility.
   * @see {@link FieldsetGridComponent.label}
   *
   * A template can also be projected as the label.
   */
  @Input() label: FieldsetGridComponent['label'] = '';

  /**
   * Show or hide label. Defaults to false, or hidden.
   * @see {@link FieldsetGridComponent.showLabel}
   */
  @Input() showLabel: FieldsetGridComponent['showLabel'] = false;

  /**
   * Limits columns of the grid. Defaults to 4.
   * @see {@link FieldsetGridComponent.columnLimit}
   */
  @Input() columnLimit: FieldsetGridComponent['columnLimit'] = 4;

  /**
   * If true, the last row of items will be centered if it has fewer items than columns. Defaults to false.
   * @see {@link FieldsetGridComponent.centerIncompleteRow}
   */
  @Input() centerIncompleteRow: FieldsetGridComponent['centerIncompleteRow'] = false;

  private destroy$ = new Subject<void>();

  constructor(private ref: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // Run change detection to update bindings for styles based on validity
      this.ref.markForCheck();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  /**
   * Retreives the label of an option, or the option itself if it is a string.
   */
  getOptionLabel(option: RadioControlOption): string {
    return typeof option === 'string' ? option : option.label;
  }

  /**
   * Retreives the value of an option, or the option itself if it is a string.
   */
  getOptionValue(option: RadioControlOption): string | number | boolean {
    return typeof option === 'string' ? option : option.value;
  }

  isOptionString(option: RadioControlOption): option is string {
    return typeof option === 'string';
  }
}
