import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { constants } from '../../../../constants/constants';

export const phoneValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumber = RegExp(constants.REGEX.phoneWithoutPunctuation); // TODO: Change back to phone.
    return phoneNumber.test(control.value) ? null : { phoneInvalid: true };
  };
};

@Directive({
  selector: '[kinPhoneValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PhoneValidatorDirective,
      multi: true,
    },
  ],
})
export class PhoneValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return phoneValidator()(control);
  }
}
