import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { GuidService } from '../services/guid.service';

// This HTTPInterceptor (https://angular.io/api/common/http/HttpInterceptor) adds the JWT token into each and every request
// when that token exists in SessionStorage.

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private guidService: GuidService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let updatedRequest = request;
    if (this.authService.retrieveToken() !== null) {
      updatedRequest = updatedRequest.clone({
        setHeaders: {
          authorization: `Bearer ${this.authService.retrieveToken()}`,
        },
      });
    }

    // Doing another clone here rather than using the above because we want to add GUID even if there is no token
    updatedRequest = updatedRequest.clone({
      setHeaders: {
        'Session-Debug-Header': this.guidService.guid,
      },
    });

    return next.handle(updatedRequest);
  }
}
