import { Pipe, PipeTransform } from '@angular/core';
import { ControlOptionObject } from 'lib-kinponents';
import { RadioOptionsAttributes } from '../models/form-elements/radio';

@Pipe({
  name: 'toRadioControlOption',
})
export class ToRadioControlOptionPipe implements PipeTransform {
  transform(value: RadioOptionsAttributes[]): ControlOptionObject[] {
    return value.map((option) => ({
      label: option.text,
      value: option.value,
    }));
  }
}
