import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormService } from './form.service';
import { ErrorTracking, Question } from '../models/question';
import { PageBuilderService } from '../page-builder.service';
import { ExternalScriptService } from '../../../services/external-scripts.service';
import { VALIDATION_MESSAGES } from '../../../constants/constants';

// This component is used as a base class for most if not all components in /form-elements.

@Component({
  selector: 'kin-base-form-field',
  template: '',
})
export class BaseFieldComponent<T extends Question> implements OnDestroy, OnChanges {
  @Input() fieldData: T;

  @Input() readonly = false;

  @Input() form: FormGroup;

  errors: ErrorTracking[] = [];

  statusListener: Subscription;

  formStatusChangeSubscription: Subscription;

  constructor(
    // TODO / NOTE: ExternalScriptService is currently only used in address.component. If we add one more
    // component that uses the service we should refactor into a second BaseField for external things
    protected externalScriptService: ExternalScriptService,
    public formService: FormService,
    protected pageBuilderService: PageBuilderService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.formStatusChangeSubscription?.unsubscribe();
      this.formStatusChangeSubscription = this.formControl?.statusChanges.subscribe(() => {
        this.updateErrors();
      });
    }
  }

  ngOnDestroy() {
    this.formStatusChangeSubscription?.unsubscribe();
  }

  trackByValue(index, item: { value: any }) {
    return item.value;
  }

  /** described by is used for aria attributes needs id to be defined */
  get describedBy(): string {
    let describedBy = '';
    const { fieldData } = this;
    const id = fieldData?.id ?? null;
    if (id) {
      if (fieldData.hint) {
        describedBy += `${id}__hint `;
      }

      if (fieldData.description) {
        describedBy += `${id}__description `;
      }

      if (fieldData.errors) {
        describedBy += `${id}__errors `;
      }
    }

    return describedBy.trimEnd();
  }

  updateErrors() {
    if (this.formControl?.errors) {
      this.fieldData.errors = Object.keys(this.formControl.errors).reduce<ErrorTracking[]>(
        (errorTrackingArray, key) => {
          if (VALIDATION_MESSAGES[key] !== undefined) {
            errorTrackingArray.push({ error: key, message: VALIDATION_MESSAGES[key] });
          }

          return errorTrackingArray;
        },
        []
      );
    }
  }

  get formControl() {
    return this.form.get(this.fieldData.name);
  }
}
