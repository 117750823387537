import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
  AsyncValidator,
  AsyncValidatorFn,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, delay, first, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { RequestService } from '../../../../services/request.service';
import { VALID } from './constants';
import { ValidationApiResponse } from './models/validation-api-response';

export const everyoneApiValidator = (requestService: RequestService): AsyncValidatorFn => {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    return of(control.value).pipe(
      delay(500),
      switchMap((phone) => {
        return requestService.post(
          `${environment.baseUrl + environment.apiPrefix}/validations/phone`,
          {
            phone,
          }
        );
      }),
      map((data) => {
        return (data as ValidationApiResponse).verification_status === VALID
          ? null
          : { phoneInvalid: true, asyncInvalid: true };
      }),
      catchError(() => of({ phoneInvalid: true, asyncInvalid: true })),
      first()
    );
  };
};

@Directive({
  selector: '[kinEveryoneApiValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: EveryoneApiValidatorDirective,
      multi: true,
    },
  ],
})
export class EveryoneApiValidatorDirective implements AsyncValidator {
  constructor(private requestService: RequestService) {}

  validate(control: AbstractControl) {
    return everyoneApiValidator(this.requestService)(control);
  }
}
