import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddressQuestion } from '../../models/form-elements/address';
import { BaseFieldComponent } from '../base-field.component';

// As of 02/2023, this version of the address component has been deprecated
// Please use the kinetic component instead.
// You can see an example implementation in projects/app-funnel/src/app/start/start.component.ts
@Component({
  selector: 'kin-form-address',
  templateUrl: './address.component.html',
})
export class FormAddressComponent
  extends BaseFieldComponent<AddressQuestion>
  implements AfterViewInit, OnDestroy
{
  @ViewChild('address') address: ElementRef;

  @ViewChild('unitNumber') unitNumber: ElementRef;

  angularReadyEvent: CustomEvent;

  requestSub: Subscription;

  ngAfterViewInit() {
    this.angularReadyEvent = new CustomEvent('kin-angular:ready');
    const label = document.createElement('span');
    label.textContent = 'Address';
    label.classList.add('input-textbox--label');
    this.externalScriptService.load('address-kinterface').then(() => {
      document.dispatchEvent(this.angularReadyEvent);
      const observeTarget = this.address.nativeElement.querySelector('[data-component]');
      const mutationObserver = new MutationObserver((mutationsList, observer) => {
        observeTarget.appendChild(label);
        observer.disconnect();
      });
      if (observeTarget) mutationObserver.observe(observeTarget, { childList: true });
    });

    this.address.nativeElement
      .querySelector('[data-component]')
      .addEventListener('autocomplete', (e: CustomEvent) => {
        this.handleAutocompleteEvent(e);
      });
  }

  ngOnDestroy() {
    this.requestSub?.unsubscribe();
  }

  handleAutocompleteEvent(e: CustomEvent) {
    const value = e.detail.state ? e.detail.display : '';

    this.form.get('street_address').setValue(value);
  }
}
