import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { SessionStorageService } from '../../services/session-storage.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'kin-kinspector-mini',
  templateUrl: './kinspector-mini.component.html',
  styleUrls: ['./kinspector-mini.component.scss'],
})
export class KinspectorMiniComponent implements OnInit {
  @ViewChild('modal') modal: ModalComponent;

  form: FormGroup;

  isCustomUrl = false;

  baseUrl: string = null;

  constructor(private sessionStorageService: SessionStorageService) {}

  // TODO: Make a KinspectorService to keep state there instead.

  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.isValid && this.modal.active) {
      this.save();
    }
  }

  ngOnInit(): void {
    if (JSON.parse(this.sessionStorageService.retrieve('kinspector'))?.api_endpoint) {
      this.baseUrl = JSON.parse(this.sessionStorageService.retrieve('kinspector'))?.api_endpoint;
      this.isCustomUrl = true;
      if (this.baseUrl !== environment.baseUrl) {
        environment.baseUrl = this.baseUrl;
      }
    } else {
      this.baseUrl = environment.baseUrl;
    }
    this.form = new FormGroup({
      api_endpoint: new FormControl('', Validators.required),
      default_endpoint: new FormControl(environment.baseUrl),
      new_design: new FormControl('no', Validators.required),
    });
  }

  open() {
    this.modal.open();
  }

  save() {
    const api = this.form.get('api_endpoint').value;
    this.form.get('api_endpoint').patchValue(api.endsWith('/') ? api.slice(0, -1) : api);
    this.sessionStorageService.save('kinspector', JSON.stringify(this.form.value));
    if (this.form.get('api_endpoint').value) {
      this.baseUrl = this.form.get('api_endpoint').value;
    }
    this.modal.close();
    window.location.href = '/start';
  }

  reset() {
    environment.baseUrl = JSON.parse(
      this.sessionStorageService.retrieve('kinspector')
    ).default_endpoint;
    this.baseUrl = environment.baseUrl;
    this.isCustomUrl = false;
    this.sessionStorageService.remove('kinspector');
    window.location.href = '/start';
  }

  resetSessionStorage() {
    this.sessionStorageService.clear();
    window.location.href = '/start';
  }

  get isValid() {
    return this.form.valid && !this.form.get('api_endpoint').value.includes('app.kin.com');
  }
}
