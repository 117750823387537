<div *ngIf="shouldShow" class="progress-bar">
  <label for="progress" class="sr-only">{{label}}</label>
  <progress id="progress" class="progress-bar__progress" [value]="value" [max]="max"></progress>
  <ul class="progress-bar__steps">
    <li *ngFor="let step of steps; let i = index;" class="progress-bar__step" [ngClass]="i <= value ? 'progress-bar__step--started' : ''">
      <div class="progress-bar__step__number">
        <span *ngIf="i+1 > value; else elseBlock">{{i+1}}</span>
        <ng-template #elseBlock>
          <kin-svg name="check" class="icon icon__small"></kin-svg>
        </ng-template>
      </div>
      <div class="progress-bar__step__label">{{step.label}}</div>
    </li>
  </ul>
</div>