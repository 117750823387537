import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { checkDate } from '../../../../utilities/date';

export const dateValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return checkDate(control.value) ? null : { dateInvalid: true };
  };
};

@Directive({
  selector: '[kinDateValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateValidatorDirective,
      multi: true,
    },
  ],
})
export class DateValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return dateValidator()(control);
  }
}
