import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// This service is a wrapper around the Browser's SessionStorage API.
// More Info: https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage

@Injectable()
export class SessionStorageService {
  sessionStorageSubscription: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() {}

  save(key: string, value: any) {
    window.sessionStorage.setItem(key, value);
    this.sessionStorageSubscription.next(key);
  }

  retrieve(key: string) {
    return window.sessionStorage.getItem(key);
  }

  retrieveMultiple(keys: string[]): any {
    const multipleKeys = {};
    keys.forEach((key) => {
      multipleKeys[key] = this.retrieve(key);
    });
    return multipleKeys;
  }

  remove(key: string) {
    window.sessionStorage.removeItem(key);
  }

  clear() {
    window.sessionStorage.clear();
  }
}
