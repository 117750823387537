import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet, ActivatedRoute } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { SplitIOService } from 'lib-kinponents';
import { TagManagerService } from './shared/services/tag-manager.service';
import {
  fadeInOutAnimation,
  slideInOutAnimation,
  TRANSITION_DURATION,
} from './shared/animations/transitionAnimations';
import { AuthService } from './shared/services/auth.service';
import { PaymentService } from './shared/services/payment.service';
import { SessionStorageService } from './shared/services/session-storage.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'kin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInOutAnimation,
    fadeInOutAnimation,
    trigger('fadeInOutOfExistence', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(TRANSITION_DURATION, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(TRANSITION_DURATION, style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('outlet') outlet: RouterOutlet;

  title = 'Kin';

  slideInOutAnimationState?: number = null;

  fadeInOutAnimationState?: number = null;

  address = '';

  isProd = environment.production;

  private destroy$ = new Subject<void>();

  private navEnd: Observable<NavigationEnd>;

  private routeAnimationHistory: string[];

  constructor(
    private authService: AuthService,
    private tagManagerService: TagManagerService,
    private paymentService: PaymentService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private splitIOService: SplitIOService,
    private route: ActivatedRoute
  ) {
    this.navEnd = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
    this.routeAnimationHistory =
      JSON.parse(this.sessionStorageService.retrieve('routeAnimationHistory')) ?? [];
  }

  ngOnInit() {
    this.setLoadPage();
    this.tagManagerService.initialize();
    this.paymentService.initialize();
    this.authService.getConfig();

    this.navEnd
      .pipe(
        filter(() => this.showAddressBar),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.address =
          this.outlet?.activatedRouteData?.addresses?.address?.standardized_address ?? '';
      });

    this.navEnd.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (this.outlet?.activatedRouteData?.transitionAnimation === 'slideInOut') {
        if (!this.routeAnimationHistory.includes(event.url)) {
          this.routeAnimationHistory.push(event.url);
          this.sessionStorageService.save(
            'routeAnimationHistory',
            JSON.stringify(this.routeAnimationHistory)
          );
        }
        this.slideInOutAnimationState = this.routeAnimationHistory.lastIndexOf(event.url);
      } else if (this.outlet?.activatedRouteData?.transitionAnimation === 'fadeInOut') {
        this.fadeInOutAnimationState = (this.fadeInOutAnimationState ?? 0) + 1;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  get showAddressBar() {
    return this.outlet?.activatedRouteData?.showAddressBar;
  }

  get hasHeaderCircle() {
    return this.outlet?.activatedRouteData?.headerCircle;
  }

  setLoadPage() {
    // Reloads page if the browser is Safari. Safari's has strong caching, which
    // made the page not render when the user went back
    if (navigator.userAgent.match(/safari/i)) {
      window.onpageshow = (event) => {
        if (event.persisted) {
          window.location.reload();
        }
      };
    }
  }

  handleHeaderResize(entries: ResizeObserverEntry[]) {
    document.documentElement.style.setProperty(
      '--header-group-height',
      `${entries[0].contentRect.height}px`
    );
  }
}
