export const environment = {
  apiPrefix: '/api/v1',
  baseUrl: 'https://app.kin.haus',
  environmentName: 'staging',
  fullstoryId: 'YVGQ5',
  gtmId: null,
  production: false,
  splitIoClientKey: 'jg52msmphbtu6qhk9p3jj9vdm6mn6vve2dfn',
  smartyKey: '17390636537911911',
};
