<footer class="footer">
  <div class="wrapper wrapper--72">
    <div class="grid--fluid">
      <div class="row">
        <div class="col col-sm-4">
          <div class="h-list">
            <span class="text text__md text__brand-gray">&copy;2016-{{ currentYear }} Kin Insurance Technology Hub, LLC.</span>
          </div>
        </div>
        <div class="col col-sm-8">
          <div class="is-visible is-hidden@small">
            <div class="vr vr__x3"></div>
          </div>
          <nav class="v-to-h-list v-to-h-list--right" data-rspec="footer-links">
            <a href="https://kin.com/terms" class="text text__md text__brand-gray text__link text__secondary-hover" target="_blank" rel="noopener">Terms of Service</a>
            <a href="https://www.kin.com/privacy" class="text text__md text__brand-gray text__link text__secondary-hover" target="_blank" rel="noopener">Privacy Policy</a>
            <a href="https://www.kin.com/privacy/sharing" class="text text__md text__brand-gray text__link text__secondary-hover" target="_blank" rel="noopener">Do Not Sell My Info</a>
            <a href="https://www.kin.com/accessibility" class="text text__md text__brand-gray text__link text__secondary-hover" target="_blank" rel="noopener">Accessibility</a>
            <button type="button" *ngIf="isStaging" class="text text__md text__brand-gray btn__link text__secondary-hover" (click)="kinspector.open()">Kinspector</button>
          </nav>
        </div>
      </div>
    </div>
  </div>
</footer>

<kin-kinspector-mini #kinspector></kin-kinspector-mini>