import { Component } from '@angular/core';
import { AttachedRadioQuestion } from '../../models/form-elements/attached-radio';
import { BaseFieldComponent } from '../base-field.component';

@Component({
  selector: 'kin-form-attached-radio',
  templateUrl: './attached-radio.component.html',
})
export class FormAttachedRadioComponent extends BaseFieldComponent<AttachedRadioQuestion> {
  onChange(e: Event, value: string) {
    this.formService.emitCallerElementId(this.fieldData.value);
    this.form.get(this.fieldData.name).setValue(value);
  }
}
