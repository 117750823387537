import {
  Directive,
  Input,
  OnInit,
  ElementRef,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';

// Thanks, https://gist.github.com/rami-alloush/3ee792fd0647b73de5f863a2719c78c6

@Directive({
  selector: '[kinMask]',
})
export class MaskDirective implements OnInit, OnChanges {
  @Input('kinMask') mask: string;

  private readonly formatRegExpMap = {
    '0': /[0-9]/,
    a: /[a-z]/,
    A: /[A-Z]/,
    B: /[a-zA-Z]/,
  };

  private readonly allFormatsGlobal = this.getAllFormatRegexp('g');

  private readonly allFormats = this.getAllFormatRegexp();

  private inputElem: HTMLInputElement;

  private lastMaskedValue = '';

  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('input')
  onInput() {
    if (this.mask?.length > 0) {
      const cursorIndex = this.inputElem.selectionEnd;
      const initialLength = this.ngControl.value.length;
      const wasDeleted = this.lastMaskedValue.length > initialLength;
      this.ngControl.control.patchValue(this.maskValue(this.ngControl.value, wasDeleted));
      // Preserve cursor location
      if (wasDeleted) {
        if (this.allFormats.test(this.ngControl.value.substr(cursorIndex - 1, 1))) {
          this.inputElem.setSelectionRange(cursorIndex, cursorIndex);
        } else {
          // Move one back if the character the cursor landed on is a separator character
          this.inputElem.setSelectionRange(cursorIndex - 1, cursorIndex - 1);
        }
      } else {
        const addedChars = this.ngControl.value.length - initialLength;
        // Account for added separator characters
        this.inputElem.setSelectionRange(cursorIndex + addedChars, cursorIndex + addedChars);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.mask.firstChange) {
      this.ngControl.control.patchValue(this.maskValue(this.ngControl.value));
    }
  }

  ngOnInit() {
    this.inputElem = this.el.nativeElement;
    if (this.ngControl.value && this.mask?.length > 0) {
      this.ngControl.control.patchValue(this.maskValue(this.ngControl.value));
    }
  }

  private maskValue(val: string, fromDelete = false): string {
    if (!val || !this.mask) {
      this.lastMaskedValue = val;
      return val;
    }
    const maskedValue = this.valueToFormat(val, this.mask, fromDelete);
    this.lastMaskedValue = maskedValue;
    return maskedValue;
  }

  /**
   * Apply format to a value string
   *
   * Format can be constructed from next symbols:
   *  - '0': /[0-9]/,
   *  - 'a': /[a-z]/,
   *  - 'A': /[A-Z]/,
   *  - 'B': /[a-zA-Z]/
   *
   * Example: 'AAA-00BB-aaaa'
   * will accept 'COD-12Rt-efww'
   *
   * @param valueWithoutCountryCode Current value
   * @param mask Mask format string
   */
  private valueToFormat(value: string, mask: string, fromDelete: boolean): string {
    // TODO refactor here if we need to support additional phone country codes
    const usCountryCode = '+1';
    // saved phone numbers have been normalized by prepending the US country code
    // this check removes the initial '+1' since we're not showing it in the client masking
    const valueWithoutCountryCode =
      value.substring(0, 2) === usCountryCode ? value.slice(2) : value;
    const maskedValueArray = this.unmaskValue(valueWithoutCountryCode).split('');
    for (let sepCharPosition = 0; sepCharPosition < mask.length; sepCharPosition += 1) {
      const valueChar = maskedValueArray[sepCharPosition];
      // Do skip position if no value was inputted at this position
      if (valueChar !== undefined) {
        const sepChar: string = mask[sepCharPosition];
        const formatRegex = this.getFormatRegexp(sepChar);
        const isSeparator = sepChar && !formatRegex;
        if (isSeparator) {
          if (sepCharPosition === 0) {
            // Adds separator if it is the first character
            maskedValueArray.splice(sepCharPosition, 0, sepChar);
          }
        } else if (!(valueChar && formatRegex?.test(valueChar))) {
          // removes character if its the wrong format
          maskedValueArray.splice(sepCharPosition, 1);
        }

        // Add next character if it is a separator
        const nextSepCharPosition = sepCharPosition + 1;
        const nextSepChar = mask[nextSepCharPosition];
        const isSeparatorNext = nextSepChar && !this.getFormatRegexp(nextSepChar);
        const isLastValue = sepCharPosition === maskedValueArray.length - 1;
        if (isSeparatorNext && !(fromDelete && isLastValue)) {
          maskedValueArray.splice(nextSepCharPosition, 0, nextSepChar);
        }
      }
    }
    // Join all parsed value, limiting length to the one specified in format
    return maskedValueArray.join('').substr(0, mask.length);
  }

  private unmaskValue(value: string): string {
    const unmaskedMatches = value.replace(' ', '').match(this.allFormatsGlobal);
    return unmaskedMatches ? unmaskedMatches.join('') : '';
  }

  private getAllFormatRegexp(flags?: string) {
    const allFormatsStr = `(${Object.values(this.formatRegExpMap)
      .map((regex) => regex.toString().slice(1, -1))
      .join('|')})`;
    return new RegExp(allFormatsStr, flags);
  }

  private getFormatRegexp(formatChar: string): RegExp | null {
    return formatChar && this.formatRegExpMap[formatChar] ? this.formatRegExpMap[formatChar] : null;
  }
}
