import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightText',
})
export class HighlightTextPipe implements PipeTransform {
  private readonly regexSanitizer = /[.*+\-?^${}()|[\]\\]/g;

  transform(text: string, textToHighlight: string): string {
    if (!(textToHighlight?.length > 0)) {
      return text;
    }
    const sanitizedText = textToHighlight.replace(this.regexSanitizer, '\\$&');
    const highlightMatch = new RegExp(`(${sanitizedText})`, 'gi');
    return text.replace(highlightMatch, (match) => `<span class="text__bold">${match}</span>`);
  }
}
