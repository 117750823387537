import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { accountForUTC, getDifferenceInYears } from '../../../../utilities/date';

export function minAgeYears(years: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const today = accountForUTC(new Date(), true);
    const selectedDate = accountForUTC(new Date(control.value));

    const yearDifference = getDifferenceInYears(today, selectedDate);

    return yearDifference >= years ? null : { minAgeYears: true };
  };
}

@Directive({
  selector: '[kinMinAgeYears]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: minAgeYears,
      multi: true,
    },
  ],
})
export class MinAgeYearsDirective implements Validator {
  @Input('kinMinAgeYears') value: number;

  validate(control: AbstractControl): ValidationErrors | null {
    return minAgeYears(this.value)(control);
  }
}
