<div [ngClass]="{'input--constrained': shouldConstrainInput, 'input--never-activate-label': currentlyHasNoValue, 'kin-async-invalid': shouldShowAsyncErrorMessage}">
  <ng-container [ngSwitch]="fieldData.type">
    <ng-container *ngIf="fieldData.description">
      <div class="text text__md" id="{{ fieldData.id }}__description" [innerHTML]="fieldData.description"></div>
      <div class="vr"></div>
    </ng-container>
    <!-- TODO: Remove $any if someday type narrowing works with ngSwitch -->
    <kin-form-text
      *ngSwitchDefault
      (change)="onChange(fieldData)"
      [fieldData]="$any(fieldData)"
      [form]="form"
      [readonly]="readonly">
    </kin-form-text>
    <kin-form-hidden
      *ngSwitchCase="'hidden'"
      (change)="onChange(fieldData)"
      [fieldData]="$any(fieldData)"
      [form]="form"
      [readonly]="readonly">
    </kin-form-hidden>
    <kin-form-tel
      *ngSwitchCase="'tel'"
      (change)="onChange(fieldData)"
      [fieldData]="$any(fieldData)"
      [form]="form"
      [readonly]="readonly">
    </kin-form-tel>
    <kin-form-email
      *ngSwitchCase="'email'"
      (change)="onChange(fieldData)"
      [fieldData]="$any(fieldData)"
      [form]="form"
      [readonly]="readonly">
    </kin-form-email>
    <kin-form-select
      *ngSwitchCase="'select'"
      (change)="onChange(fieldData)"
      [fieldData]="$any(fieldData)"
      [form]="form"
      [readonly]="readonly">
    </kin-form-select>
    <kin-form-autocomplete
      *ngSwitchCase="'autocomplete'"
      (change)="onChange(fieldData)"
      [fieldData]="$any(fieldData)"
      [form]="form"
      [readonly]="readonly"
    ></kin-form-autocomplete>
    <kin-form-remote-autocomplete
      *ngSwitchCase="'remoteautocomplete'"
      (change)="onChange(fieldData)"
      [fieldData]="$any(fieldData)"
      [form]="form"
      [readonly]="readonly"
    ></kin-form-remote-autocomplete>
    <kin-form-checkbox *ngSwitchCase="'checkbox'" (change)="onChange(fieldData)" [fieldData]="$any(fieldData)" [form]="form"></kin-form-checkbox>
    <kin-form-toggle *ngSwitchCase="'toggle'" (change)="onChange(fieldData)" [fieldData]="$any(fieldData)" [form]="form"></kin-form-toggle>
    <ng-container *ngSwitchCase="'radio'">
      <kin-form-radio *ngIf="radioHasImages($any(fieldData).options)"
        [hasImages]="true"
        (change)="onChange(fieldData)"
        [fieldData]="$any(fieldData)"
        [form]="form"></kin-form-radio>
      <div class="h-list h-list--center@small" *ngIf="!radioHasImages($any(fieldData).options)">
        <ngk-form-radio class="h-list"
          [options]="$any(fieldData).options | toRadioControlOption"
          [control]="$any(form.controls[fieldData.name])"
          [showValidationMessages]="false"
          [id]="fieldData.id"
          [name]="fieldData.name"
          theme="native"
          [showLabel]="!$any(fieldData.labelless)"
          [label]="fieldData.label"
          (change)="onChange(fieldData)">
        </ngk-form-radio>
      </div>
    </ng-container>
    <kin-form-attached-radio *ngSwitchCase="'attached-radio'" (change)="onChange(fieldData)" [fieldData]="$any(fieldData)" [form]="form"></kin-form-attached-radio>
    <kin-form-auto-radio
      *ngSwitchCase="'auto-radio'"
      (onChange)="onChange(fieldData)"
      (autoSubmit)="autoSubmit(fieldData)"
      [fieldData]="$any(fieldData)"
      [form]="form"
      [readonly]="readonly"
    ></kin-form-auto-radio>
    <kin-form-datepicker *ngSwitchCase="'date'" (onChange)="onChange(fieldData)" [fieldData]="$any(fieldData)" [form]="form"></kin-form-datepicker>
    <kin-form-address *ngSwitchCase="'address'" (change)="onChange(fieldData)" [fieldData]="$any(fieldData)" [form]="form"></kin-form-address>
    <span *ngIf="formControl.touched && formControl.dirty && formControl.invalid || shouldShowAsyncErrorMessage" id="{{ fieldData.id }}__errors">
      <span *ngFor="let error of fieldData.errors; let i = index; trackBy: errorTracking" class="text text__warning text__md">
        {{ fieldData.customErrorMessages?.[error.error] ?? error.message }}
      </span>
    </span>
    <kin-form-async-validation-loader *ngIf="formControl.pending || shouldShowAsyncValidMessage" [status]="formControl.status" [fieldLabel]="fieldData.label"></kin-form-async-validation-loader>
    <kin-form-hint *ngIf="fieldData.helpText && fieldData.helpText.type === 'hint'" [helpText]="fieldData.helpText"></kin-form-hint>
    <ng-container *ngIf="fieldData.hasEligibleChildren">
      <div class="v-list" style="margin-left: 1rem; margin-top: 1rem; border-radius: 8px">
        <div *ngFor="let child of fieldData.children; let i = index; trackBy: trackByField">
          <kin-form-field [fieldData]="child" [form]="form" (change)="onChange($any(child))" *ngIf="!child.eligibility.hidden" [readonly]="readonly"></kin-form-field>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
