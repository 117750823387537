import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormAddonValidationMessagesComponent } from './form-addon-validation-messages.component';

@NgModule({
  declarations: [FormAddonValidationMessagesComponent],
  imports: [CommonModule],
  exports: [FormAddonValidationMessagesComponent],
})
export class FormAddonValidationMessagesModule {}
