<ng-container *ngIf="valid || pending">
  <div class="async-validation-loader">
    <kin-svg
      *ngIf="valid"
      [name]="'async-validation-valid'"
      [classes]="'icon'"
    ></kin-svg>
    <img
      *ngIf="pending"
      class="async-validation-loader--loader icon"
      src="/assets/icons/async-validation-pending.svg"
    />
    <span class="async-validation-loader--text text text__xl text__secondary">{{ text }}</span>
  </div>
</ng-container>
