import { TsKCookieHelper } from './cookie-helper';

/**
 * TypeScript Kin Cookies Utility Class
 * - makes accessing common cookies used by kin easy to get and set
 */
export class TsKCookies {
  // readonly list of cookie keys used at kin
  public static readonly kinCookieKeys = {
    splitIO: 'kin-splitio-anonymous-key', // unique user ID used by split.io so users stay in the same test
  };

  /**
   * Utility class specifically for the SplitIO cookie
   */
  public static getOrCreateSplitIOCookie(userIdentifier: string): string {
    const storedAnonymousKey = TsKCookieHelper.getCookie(TsKCookies.kinCookieKeys.splitIO);

    // If the cookie is already set, return the value
    if (storedAnonymousKey) {
      return storedAnonymousKey;
    }

    // If the cookie is not already set, set it and then return the value
    const uniqueValue = userIdentifier;
    TsKCookieHelper.setCookie({
      cookieName: TsKCookies.kinCookieKeys.splitIO,
      cookieValue: uniqueValue,
    });
    return uniqueValue;
  }
}
