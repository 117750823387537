import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RequestService } from './request.service';
import { environment } from '../../../environments/environment';

export interface BasicUserInfo {
  address: string;
  first_name: string;
  user_exists: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CustomerInputResponseService {
  /** expose observable stream, do not expose directly to prevent other consumers from emitting it */
  private _basicInfo$ = new BehaviorSubject<BasicUserInfo>({
    address: '',
    first_name: '',
    user_exists: false,
  });

  public basicInfo$ = this._basicInfo$.asObservable();

  constructor(private requestService: RequestService) {}

  getBasicInfo() {
    return this.requestService.get<BasicUserInfo>(
      `${environment.baseUrl + environment.apiPrefix}/customer_input_responses`
    );
  }

  setBasicInfoState(basicInfo: BasicUserInfo) {
    this._basicInfo$.next(basicInfo);
  }
}
