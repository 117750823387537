import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { constants } from '../../../../constants/constants';

export const emailValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const fullEmail = RegExp(constants.REGEX.email);
    const domain = control.value.match(constants.REGEX.emailDomain)[0];

    return fullEmail.test(control.value) && domain.includes('.') ? null : { emailInvalid: true };
  };
};

@Directive({
  selector: '[kinEmailValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidatorDirective,
      multi: true,
    },
  ],
})
export class EmailValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return emailValidator()(control);
  }
}
