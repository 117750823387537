import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormRadioComponent } from './form-radio.component';
import { SvgModule } from '../../svg/svg.module';
import { TooltipModule } from '../../tooltip/tooltip.module';
import { FieldsetGridModule } from '../../fieldset-grid/fieldset-grid.module';

@NgModule({
  declarations: [FormRadioComponent],
  imports: [CommonModule, ReactiveFormsModule, SvgModule, FieldsetGridModule, TooltipModule],
  exports: [FormRadioComponent],
})
export class FormRadioModule {}
