/**
 * helper for formatting inputs, static methods
 */

export class SanitizeHelper {
  /**
   * escapes html tags
   */
  static escapeHTML(input: string): string {
    return input
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }
}
