import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'kin-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentYear: number = new Date().getFullYear();

  constructor() {}

  get isStaging() {
    return !environment.production;
  }
}
