<div class="wrapper">
  <header class="text text__center vr vr__x3">
    <kin-svg [name]="'quality-premium-white'" [classes]="'icon icon__xxxlarge icon--circled'"></kin-svg>
    <div class="vr vr__x4"></div>
    <div class="col col-md-8 col-md-offset-2">
      <h1 data-rspec="welcome_no_name" *ngIf="!basicUserInfo.first_name" class="text text__center text__size--36">Welcome back!</h1>
      <h1 data-rspec="welcome_with_name" *ngIf="basicUserInfo.first_name" class="text text__center text__size--36">Welcome back, {{basicUserInfo.first_name}}!</h1>
    </div>
  </header>

  <div class="row center-xs">
    <div class="col col-md-8">
      <p data-rspec="resume_text" class="text text__center text__brand-gray lh--1-6 vr vr__x3">
        A great home insurance quote waits for you. Would you like to pick up where you left off and resume your quote?
      </p>
      <div *ngIf="basicUserInfo.address" class="row center-xs">
        <p class="text text__center text__bold text__brand-gray vr vr__x10">
          <kin-svg [name]="'location-pin'" [classes]="'icon icon__xsmall'" class="address-icon"></kin-svg>{{basicUserInfo.address}}
        </p>
      </div>
      <div class="row center-sm">
        <button data-rspec="resume_quote_button" class="btn vr vr__x4" (click)="onClick()">Resume My Quote</button>
      </div>
      <div class="text text__center text__hint">
        <p>If you would like to start a new quote call us at <a href="{{ phoneNumber | formatPhone: 'url' }}" class="text text__link text__link--borderless text__block">{{ phoneNumber | formatPhone: 'dot' }}</a></p>
      </div>
    </div>
  </div>
</div>
