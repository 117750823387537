import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { LoaderService } from '../shared/services/loader.service';
import {
  BasicUserInfo,
  CustomerInputResponseService,
} from '../shared/services/customer-input-response.service';
import { TrackingService } from '../shared/services/tracking.service';
import { constants } from '../shared/constants/constants';
import { KinActivatedRoute } from '../shared/utilities/kin-routes';

@Component({
  selector: 'kin-resume-my-quote',
  templateUrl: './resume-my-quote.component.html',
  styleUrls: ['./resume-my-quote.component.scss'],
})
export class ResumeMyQuoteComponent implements OnInit, OnDestroy {
  phoneNumber = constants.PHONE_NUMBER;

  basicUserInfo: BasicUserInfo = {
    first_name: '',
    address: '',
    user_exists: false,
  };

  private unSubAll$ = new Subject<void>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    @Inject(ActivatedRoute) private route: KinActivatedRoute,
    private customerInputResponseService: CustomerInputResponseService,
    private trackingService: TrackingService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.queryParams.token) {
      this.authService.saveToken(this.route.snapshot.queryParams.token);
    }
    this.trackingService.trackPageView();
    this.customerInputResponseService
      .getBasicInfo()
      .pipe(takeUntil(this.unSubAll$))
      .subscribe((basicUserInfo) => {
        this.basicUserInfo = basicUserInfo;
      });
  }

  ngOnDestroy() {
    this.unSubAll$.next();
    this.unSubAll$.complete();
  }

  onClick() {
    this.trackingService.trackPageSubmit(this.basicUserInfo);
    this.loaderService.enable();

    if (this.authService.retrieveToken() === null) {
      this.router.navigate(['start']);
    } else {
      this.router.navigate(['quote']);
    }
  }
}
