<button class="tooltip__button"
  [class.tooltip__button--inline]="iconInline"
  cdkOverlayOrigin
  #button="cdkOverlayOrigin"
  type="button"
  (click)="this.handleButtonClick($event)"
  [attr.aria-describedby]="id">
  <ngk-svg [name]="icon"
    classes="icon icon__secondary icon__{{iconSize}} icon__{{iconSize}}--fixed icon__block"></ngk-svg>
  <span class="sr-only">{{buttonScreenReaderText}}</span>
</button>

<ng-template cdkConnectedOverlay
(overlayOutsideClick)="handleOverlayOutsideClick($event)"
[cdkConnectedOverlayOrigin]="button"
[cdkConnectedOverlayOpen]="showContent"
[cdkConnectedOverlayDisableClose]="false"
[cdkConnectedOverlayPositions]="tooltipPositions"
(positionChange)="handlePositionChange($event)">
  <div
    class="tooltip__content tooltip__caret tooltip__caret--{{caretPosition.x}} tooltip__caret--{{caretPosition.y}}"
    [id]="id"
    [attr.aria-expanded]="showContent">
    <p *ngIf="text">{{text}}</p>
    <ng-content></ng-content>
  </div>
</ng-template>
