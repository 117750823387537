import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// This is a global service to activate the component kin-message.
export interface Message {
  name: string;
  message: string;
  type: 'error' | 'warning' | 'info';
}

@Injectable()
export class MessageService {
  messages: Set<Message> = new Set<Message>();

  messagesChange: BehaviorSubject<Set<Message>> = new BehaviorSubject<Set<Message>>(this.messages);

  constructor() {}

  public addMessage(error: Message): void {
    this.messages.add(error);
    this.messagesChange.next(this.messages);
  }

  // Might want to change to a unique key.
  public removeMessage(error: Message): void {
    this.messages.delete(error);
    this.messagesChange.next(this.messages);
  }

  public clearMessages(): void {
    this.messages.clear();
    this.messagesChange.next(this.messages);
  }
}
