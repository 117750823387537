<ng-container *ngIf="loading">
  <div class="loader" tabindex="-1" aria-live="assertive" [@toggle]="loading ? 'active' : 'inactive'">
    <div class="loader--overlay"></div>
    <div class="loader--message">{{ message }}</div>
    <div class="loader--spinner">
      <div class="loader--spinner--circle loader--spinner--circle__outer">
        <div class="loader--spinner--circle loader--spinner--circle__inner"></div>
      </div>
    </div>
  </div>
</ng-container>