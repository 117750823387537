<fieldset [formGroup]="checkboxGroup">
  <legend [ngClass]="{ 'sr-only': singleInput }">{{fieldData.label}}</legend>
  <ul class="v-list">
    <li *ngFor="let option of fieldData.options; index as i; trackBy: trackByValue;">
      <label class="checkbox" for="{{ fieldData.id }}-{{i}}">
        <input
          class="checkbox__control"
          type="checkbox"
          id="{{ fieldData.id }}-{{ i }}"
          [name]="option.value"
          [attr.aria-describedby]="describedBy"
          [formControlName]="option.value"
          (change)="onChange()"
          [value]="option.value"
          [checked]="option.checked"
          [required]="fieldData.required">
        <span class="checkbox__label" [innerHTML]="fieldData.options[i].text"></span>
      </label>
    </li>
  </ul>
</fieldset>
