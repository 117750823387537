<div #modal class="k-modal" [id]="id" [ngClass]="{'is-active': this.active}" role="dialog" aria-modal="true">
  <div class="k-modal__overlay"></div>
  <div class="k-modal__content">
    <div class="k-modal__content__header">
      <ng-content select="[header]"></ng-content>
      <div class="k-modal__content__header__close">
        <button type="button" (click)="this.close()" data-rspec="modal_close_button">
          <span class="sr-only">Close Modal</span>
          <span aria-hidden="true">
            <svg focusable="false" class="n-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11">
              <path
                d="M7.216 5.625l3.712 3.712-1.59 1.591-3.713-3.712-3.712 3.712-1.591-1.59 3.712-3.713L.322 1.913 1.912.322l3.713 3.712L9.337.322l1.591 1.59-3.712 3.713z"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
    <div class="k-modal__content__body">
      <ng-content select="[body]"></ng-content>
    </div>
    <div class="k-modal__content__footer">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>