<fieldset [formGroup]="form">
  <legend>
    <span [ngClass]="{ 'sr-only': fieldData.labelless }">{{ fieldData.label }}</span>
    <span class="sr-only">Clicking a button will advance you to the next page.</span>
    <div class="vr"></div>
  </legend>
  <div class="vr vr__x3"></div>
  <div class="h-grid h-grid--max-{{ fieldData.options.length > 4 ? '4' : fieldData.options.length }}">
    <div
      class="radio-card"
      [ngClass]="{
        'radio-card__no-image': !option.image,
        'radio-card__full-width': option.image?.type === 'image'
      }"
      *ngFor="let option of fieldData.options; index as i; trackBy: trackByValue"
    >
      <button
        id="{{ fieldData.id }}-{{ i }}"
        type="button"
        class="radio-card--inner"
        (click)="onClick($event, option.value)"
        name="{{ fieldData.name }}"
        [value]="option.value"
      >
        <span class="radio-card--inner">
          <span
            class="radio-card--inner--image"
            [ngClass]="{ 'radio-card--inner--image__full-width': option.image?.type === 'image' }"
            *ngIf="option.image"
          >
            <kin-svg *ngIf="option.image?.type === 'icon'" [name]="option.image.name"></kin-svg>
            <img
              *ngIf="option.image?.type === 'image'"
              src="/assets/img/images/{{ option.image.name }}"
              alt="{{ option.image.description }}"
            />
            <div class="vr vr__x4"></div>
          </span>
          <span class="radio-card--inner--background"></span>
          <span
            class="radio-card--inner--text"
            [ngClass]="{
              'radio-card--inner--text__has-tooltip': !!option.helpText && option.helpText.type === 'tooltip'
            }"
            >{{ option.text }}</span
          >
          <span class="radio-card--inner--description">{{ option.description }}</span>
        </span>
      </button>
      <kin-form-tooltip
        *ngIf="!!option.helpText && option.helpText.type === 'tooltip'"
        [id]="option.value + '__tooltip'"
        [helpText]="option.helpText"
        [form]="form"
      >
      </kin-form-tooltip>
    </div>
  </div>
</fieldset>
