<fieldset [formGroup]="form" class="input--constrained">
  <div #address>
    <label for="" class="input-textbox external--address-autocomplete" data-component="address-autocomplete" data-name="address" data-placeholder=" ">
      
    </label>
  </div>
  <div *ngIf="form.value.street_address">
    <div class="vr vr__x2"></div>
    <label for="" class="input-textbox vr vr__x4">
      <!-- TODO debug unit_number console error -->
      <input class="input-textbox--input" placeholder=" " #unitNumber type="text" formControlName="unit_number" aria-label="Unit"> 
      <span class="input-textbox--label">Unit</span>
    </label>
    <ng-container>
      <div class="text text__hint" id="street_address_unit__hint"><strong>Optional:</strong> You only need to enter this if you live in a townhouse or condo.</div>
    </ng-container>
  </div>
</fieldset>
