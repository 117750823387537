<div class="testimonial">
  <span class="sr-only">{{ elementData.author }} said </span>
  <blockquote class="testimonial__quote">
    {{ elementData.content }}<span aria-hidden="true">&rdquo;</span>
  </blockquote>
  <ng-container *ngIf="elementData.url">
    <a
      href="{{ elementData.url }}"
      class="testimonial__author testimonial__author--link external-link"
      rel="noopener"
      target="_blank"
    >
      {{ elementData.author }}
      <span class="sr-only">opens in new window</span>
    </a>
  </ng-container>
  <ng-container *ngIf="!elementData.url">
    <span class="testimonial__author">{{ elementData.author }}</span>
  </ng-container>
</div>
