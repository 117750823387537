// FYI: This file was taken and adapted from https://github.com/kin/dot-com/blob/master/app/javascript/components/shared/validation/utilities/date.ts

const dayInMilliseconds = 1000 * 60 * 60 * 24;

export const accountForUTC = (date: Date, resetHours: boolean = false): Date => {
  let dateString = date.toUTCString();
  const offset: number = date.getTimezoneOffset() / 60;

  if (Number.isInteger(offset)) {
    const offsetString = offset < 10 && offset > 0 ? `0${offset}` : offset;
    dateString = `${dateString + (offset < 0 ? '+' : '-') + offsetString}00`;
  }

  const newDate = new Date(dateString);

  if (resetHours) {
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
  }

  return newDate;
};

export const getAgeInDays = (queryDate: Date, startDate = new Date()): number => {
  // startDate defaults to today
  const differenceInMilliseconds =
    accountForUTC(startDate, true).valueOf() - accountForUTC(queryDate, true).valueOf();

  return Math.round(differenceInMilliseconds / dayInMilliseconds);
};

export const getDifferenceInDays = (date1: Date, date2: Date): number => {
  // this method differs from getAgeInDays in that it returns absolute value
  const difference = Math.abs(date1.valueOf() - date2.valueOf());

  const differenceInDays = Math.round(difference / dayInMilliseconds);

  return differenceInDays;
};

export const getDifferenceInYears = (date1: Date, date2: Date): number => {
  const yearInMilliseconds = dayInMilliseconds * 365.25;

  const difference = Math.abs(date1.valueOf() - date2.valueOf());

  const differenceInYears = Math.floor(difference / yearInMilliseconds);

  return differenceInYears;
};

export const getLastMonth = (month: number, year: number): Date => {
  const date = new Date(year, month, 14);

  if (month !== 0) {
    date.setMonth(month - 1);
  } else {
    date.setFullYear(year - 1);
    date.setMonth(11);
  }

  date.setDate(1);

  return date;
};

export const getNextMonth = (month: number, year: number): Date => {
  const date = new Date(year, month, 14);

  if (month !== 11) {
    date.setMonth(month + 1);
    date.setDate(1);
  } else {
    date.setFullYear(year + 1);
    date.setMonth(0);
    date.setDate(1);
  }

  return date;
};

export const calculateLastDayOfMonth = (month: number, year: number): number => {
  const date = new Date(year, month, 14);

  if (month !== 11) {
    date.setFullYear(year);
    date.setMonth(month + 1);
  } else {
    date.setFullYear(year + 1);
    date.setMonth(0);
  }

  date.setDate(0);

  return date.getDay();
};

export const daysInMonth = (month: number, year: number): number => {
  // Since we're really dealing with UNIX timestamps,
  // let's set the date to the middle of the month to avoid
  // issues relating to "February doesn't have 30 days!" bugs.
  const date = new Date(year, month, 14);

  if (month !== 11) {
    date.setFullYear(year);
    date.setMonth(month + 1);
  } else {
    date.setFullYear(year + 1);
    date.setMonth(0);
  }

  date.setDate(0);

  return date.getDate();
};

export const getFirstDayOfMonth = (month: number, year: number): number => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(month);
  date.setFullYear(year);

  return date.getDay();
};

// This expects the following format: MM/DD/YYYY
export const checkDate = (value: string | Date) => {
  if (typeof value === 'string') {
    const date = new Date(value);
    const temp = value.split('/');
    const dateParts: number[] = [];

    temp.forEach((part, i) => {
      dateParts[i] = Number.parseInt(part, 10);
    });
    if (dateParts[0] !== 0) {
      dateParts[0] -= 1;
    }

    // Even though 198 AD happened, we don't care.
    if (date.getFullYear() < 1000) {
      return false;
    }

    return (
      date.getMonth() === dateParts[0] &&
      date.getFullYear() === dateParts[2] &&
      date.getDate() === dateParts[1]
    );
  }
  return !Number.isNaN(Number(value));
};

export const getDateWithoutTime = (date?: Date) => {
  const theDate = date ?? new Date();
  return new Date(theDate.getFullYear(), theDate.getMonth(), theDate.getDate());
};

export const getBeginningOfMonth = (date?: Date) => {
  const theDate = date ?? new Date();
  return new Date(theDate.getFullYear(), theDate.getMonth(), 1);
};

export const changeDateByDays = (offset: number, date?: Date) => {
  const theDate = date ?? new Date();
  return new Date(theDate.getFullYear(), theDate.getMonth(), theDate.getDate() + offset);
};

export const changeDateByYears = (offset: number, date?: Date) => {
  const theDate = date ?? new Date();
  return new Date(theDate.getFullYear() + offset, theDate.getMonth(), theDate.getDate());
};
