<ngk-fieldset-grid class="vr__x4"
  [id]="id"
  [form]="showValidationMessages ? control : undefined"
  [label]="label"
  [showLabel]="showLabel"
  [columnLimit]="columnLimit"
  [centerIncompleteRow]="centerIncompleteRow">
  <!-- Forward the projected "label" template to ngk-fieldset-grid -->
  <ng-content label select="[label]"></ng-content>

  <ng-template ngkFieldsetGridItem
    let-itemId
    *ngFor="let option of $any(options)">
    <input type="radio"
      class="form-radio__input form-radio__input--{{theme}}"
      id="{{ id }}-{{ getOptionLabel(option) }}"
      [name]="name"
      [attr.name]="name"
      [value]="getOptionValue(option)"
      [formControl]="control" />
      <!-- TODO: Consider an alternative to ternary statements in case there is a third option -->
    <ng-container [ngTemplateOutlet]="theme === 'native' ? nativeLabel : theme === 'card' ? cardLabel : buttonLabel"
      [ngTemplateOutletContext]="{ $implicit: option }">
    </ng-container>
    
  </ng-template>
</ngk-fieldset-grid>

<ng-template #cardLabel let-option>
    <label class="form-radio__label form-radio__label--card text__center"
      for="{{ id }}-{{ getOptionLabel(option) }}">
      <div class="form-radio__selection-indicator bubble">
        <div class="bubble--background form-radio__selection-indicator-background">
          <div class="bubble--content form-radio__selection-indicator-content">
            <ngk-svg name="check"
              classes="icon icon__medium icon__white-fill icon__block">
            </ngk-svg>
          </div>
        </div>
      </div>
      <div class="form-radio__content">
        <ngk-svg *ngIf="!isOptionString(option) && option.image?.sourceType === 'sprite'"
          class="vr__x2"
          [name]="$any(option.image!.path)"
          classes="icon icon__xxlarge icon__block"></ngk-svg>
        <div class="text__brand-gray">
          {{ getOptionLabel(option) }}
        </div>
      </div>
      <div class="form-radio__tooltip">
        <ngk-tooltip *ngIf="!isOptionString(option) && option.tooltip"
          [icon]="option.tooltip.icon"
          [text]="option.tooltip.text"></ngk-tooltip>
      </div>
    </label>
</ng-template>

<ng-template #nativeLabel let-option>
    <label class="form-radio__label form-radio__label--native" 
      for="{{ id }}-{{ getOptionLabel(option) }}">
          {{ getOptionLabel(option) }}
    </label>
</ng-template>

<ng-template #buttonLabel let-option>
  <label class="form-radio__label form-radio__label--button text__center" 
    for="{{ id }}-{{ getOptionLabel(option) }}">
        {{ getOptionLabel(option) }}
  </label>
</ng-template>
