<fieldset id="{{ id }}"
  [attr.aria-describedby]="describedBy">
  <legend class="fieldset-grid__label" [class.sr-only]="!showLabel"
    [class.fieldset-grid__invalid]="form?.invalid && form?.touched && form?.dirty"
  >
    {{ label }}
    <ng-content select="[label]"></ng-content>
  </legend>
  <div *ngIf="showLabel" class="vr__x2"></div>
  <div class="grid--flush row"
    [class.center-sm]="centerIncompleteRow">
    <div *ngFor="let item of gridItems; index as i;"
      class="fieldset-grid__item col-xs-12 col-sm-6 col-md-{{this.gridItemSize}}">
      <ng-container [ngTemplateOutlet]="item.templateRef"
        [ngTemplateOutletContext]="{ $implicit: id + '-' + i }"></ng-container>
    </div>
  </div>
</fieldset>
<ngk-form-addon-validation-messages *ngIf="showValidationMessages && form"
  [controlId]="id"
  [touched]="form.touched"
  [dirty]="form.dirty"
  [invalid]="form.invalid"
  [errors]="form.errors">
</ngk-form-addon-validation-messages>
