<div class="autocomplete">
  <label class="input-textbox" [for]="fieldData.id" [formGroup]="form" >
    <input
      type="text"
      [id]="fieldData.id"
      class="input-textbox--input"
      role="combobox"
      autocomplete="off"
      placeholder=" "
      [formControlName]="fieldData.name"
      [required]="fieldData.required"
      aria-autocomplete="list"
      aria-haspopup="listbox"
      [attr.aria-describedby]="describedBy"
      [attr.aria-controls]="fieldData.id + '__listbox'"
      [attr.aria-expanded]="isSuggestionListActive"
      (input)="handleInput($event)"
      (focus)="handleFocus($event)"
      (blur)="handleBlur()"
      (keydown.arrowup)="moveToPreviousSuggestion($event)"
      (keydown.arrowdown)="moveToNextSuggestion($event)"
      (keydown.escape)="handleEscape()"
      (keydown.enter)="handleEnter($event)"
      (keydown)="handleKeydown($event)"
      kinAutocompleteControlValueAccessor
    />
    <span class="input-textbox--label">{{ fieldData.label }}</span>
  </label>
  <div class="autocomplete__list" *ngIf="isSuggestionListActive">
    <ul [id]="fieldData.id + '__listbox'" role="listbox">
      <li *ngFor="let listOption of suggestionList; let index = index"
          class="autocomplete__list-item" 
          [ngClass]="{'autocomplete__list-item--selected': index === currentSuggestionIndex}" 
          (mousedown)="selectSuggestion(listOption)" 
          [innerHTML]="listOption.text | highlightText: sansSuggestionInputText"
          role="option"
          >
      </li>
    </ul>
  </div>
</div>
