import { Component } from '@angular/core';
import { HiddenQuestion } from '../../models/form-elements/hidden';
import { BaseFieldComponent } from '../base-field.component';

@Component({
  selector: 'kin-form-hidden',
  templateUrl: './hidden.component.html',
  styleUrls: ['./hidden.component.scss'],
})
export class FormHiddenComponent extends BaseFieldComponent<HiddenQuestion> {}
