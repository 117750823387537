import { Component, Input } from '@angular/core';

@Component({
  selector: 'kin-svg',
  styleUrls: ['./svg.component.scss'],
  templateUrl: './svg.component.html',
})
export class SvgComponent {
  @Input() name: string;

  @Input() classes: string;

  constructor() {}
}
