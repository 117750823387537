import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from './request.service';
import { SanitizeHelper } from '../utilities/sanitize_helper';

const SELECTORS = [
  "input[type='text']:not(.hidden):not([data-validate-credit-card]):not([data-fullstory-exclude]):not([autocomplete^=cc-])",
  "input[type='email']:not(.hidden):not([data-fullstory-exclude])",
  "input[type='tel']:not(.hidden):not([data-fullstory-exclude])",
];

// This service is to sanitize the DOM, add in the values received from the form, and submit the HTML and CSS stylesheet URLs to our backend.
// This was requested by our legal team to have an understanding of what the user saw when they agreed to certain things.
// This service is a generalized service; it can be used anywhere.

@Injectable()
export class ScreenshotService {
  document: HTMLElement;

  styleUrls: string[] = [];

  constructor(private requestService: RequestService) {}

  public takeScreenshot(category: string, doc: HTMLElement = null): void {
    if (doc === null) {
      this.document = document.body.cloneNode(true) as HTMLElement; // We want to copy, not change in place!
    } else {
      this.document = doc;
    }

    this.document.querySelector('kin-loader').remove();
    this.document.querySelectorAll('script').forEach((el) => el.remove());
    this.styleUrls = Array.from(document.styleSheets).map((sheet) => sheet.href);

    this.document
      .querySelectorAll(SELECTORS.toString())
      .forEach((input: HTMLInputElement | HTMLSelectElement) => {
        const updatedInput = input;
        updatedInput.value = SanitizeHelper.escapeHTML(input.value);
      });

    this.document.querySelectorAll("input[type='checkbox']").forEach((input: HTMLInputElement) => {
      if (input.checked && input.getClientRects().length !== 0) {
        input.setAttribute('checked', 'checked');
      }
    });

    const radioGroupNames = new Set();
    this.document.querySelectorAll("input[type='radio']").forEach((input: HTMLInputElement) => {
      if (input.getClientRects().length !== 0) {
        radioGroupNames.add(input.name);
      }
    });

    radioGroupNames.forEach((name) => {
      const group = Array.from(this.document.querySelectorAll(`input[name=${name}]`));
      const selected = group.find((radio: HTMLInputElement) => radio.checked === true);
      const toDeselect = group.filter(
        (radio: HTMLInputElement) =>
          radio.getAttribute('checked') !== null && radio.checked !== true
      );
      selected.setAttribute('checked', 'checked');
      toDeselect.forEach((radio) => radio.removeAttribute('checked'));
    });

    this.document.querySelectorAll('img').forEach((img) => {
      img.setAttribute('src', `https://welcome.kin.com/${img.getAttribute('src')}`);
    });

    const styleSheetUrls = Array.from(document.styleSheets).map((sheet) => sheet.href);
    const userEmail = this.document.querySelector('[id*="email"]')
      ? (this.document.querySelector('[id*="email"]') as HTMLInputElement).value
      : null;
    const pageUrl = window.location.href;

    this.requestService
      .post(`${environment.baseUrl}/screenshots`, {
        markup: this.document.outerHTML,
        style_sheet_urls: styleSheetUrls,
        user_email: userEmail,
        page_url: pageUrl,
        category,
      })
      .toPromise();
  }
}
