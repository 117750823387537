import { v4 as uuidv4 } from 'uuid';

/**
 * Common functions to support aria attributes in components
 * Methods added to this helper should be:
 * - Immutable & declarative
 * - Generic and not specific to any component that may consume it
 */
export class AriaHelper {
  static buildDescribedByString(
    id: string,
    options: { hasHint?: boolean; hasDescription?: boolean; hasErrors?: boolean }
  ): string | null {
    let describedBy = '';
    const { hasHint, hasDescription, hasErrors } = options;
    if (id && (hasHint || hasDescription || hasErrors)) {
      if (hasHint) {
        describedBy += `${id}__hint `;
      }

      if (hasDescription) {
        describedBy += `${id}__description `;
      }

      if (hasErrors) {
        describedBy += `${id}__errors `;
      }
      return describedBy.trimEnd();
    }
    return null;
  }

  static getGeneratedId(namespace: string): string {
    return `${namespace}_${uuidv4()}`;
  }
}
