import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { TooltipComponent } from './tooltip.component';
import { SvgModule } from '../svg/svg.module';

@NgModule({
  declarations: [TooltipComponent],
  imports: [CommonModule, SvgModule, OverlayModule],
  exports: [TooltipComponent],
})
export class TooltipModule {}
