import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'kin-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toggle', [
      state(
        '*',
        style({
          opacity: 1,
        })
      ),
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(
          100,
          style({
            opacity: 1,
          })
        ),
      ]),
      transition(':leave', [
        style({
          opacity: 1,
        }),
        animate(
          100,
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class LoaderComponent implements OnDestroy {
  loading = false;

  message: string;

  loadingListener: Subscription;

  constructor(private loaderService: LoaderService, private changeDetectionRef: ChangeDetectorRef) {
    // Debounce Time: If the request completes in under the time given (in milliseconds), then we don't want to show the loader.
    this.loadingListener = this.loaderService.loading
      .pipe(debounceTime(100))
      .subscribe((loading) => {
        this.loading = loading;
        this.message = this.loaderService.message;
        this.changeDetectionRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.loadingListener.unsubscribe();
  }
}
