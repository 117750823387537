import { FormControl } from '@angular/forms';
/**
 * Form Component Base
 * @desc this is a base class for most of the form elements. Only abstract methods/properties should be defined here.
 */
export abstract class BaseFormComponent {
  /**
   * HTML id attribute of the form element
   */
  abstract id: string;

  /**
   * Label of the form element
   */
  abstract label: string;

  /**
   * Angular ReactveForms FormControl object reference
   */
  abstract control: FormControl;
}
