import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: `[kinAutocompleteControlValueAccessor]`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AutocompleteControlValueAccessorDirective,
    },
  ],
})
export class AutocompleteControlValueAccessorDirective implements ControlValueAccessor {
  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_: any) => {};

  onTouched = () => {};

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  protected setProperty(key: string, value: any): void {
    this._renderer.setProperty(this._elementRef.nativeElement, key, value);
  }

  writeValue(value: any): void {
    let normalizedValue = '';
    if (this.isStringlike(value?.text)) {
      normalizedValue = value.text;
      // onChange sets value for FormControl
      // emit empty string if .text is empty for 'required' validation
      // TODO if back-end ever doesn't need the mortgagee_id, we can get simplify here
      this.onChange(value.text == null || value.text === '' ? '' : value);
    } else {
      if (this.isStringlike(value)) {
        normalizedValue = value;
      }
      this.onChange(normalizedValue);
    }
    this.setProperty('value', normalizedValue);
  }

  private isStringlike(value: any) {
    return (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      typeof value === 'bigint'
    );
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.setProperty('disabled', isDisabled);
  }
}
