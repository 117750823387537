import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'kin-address-bar',
  templateUrl: './address-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressBarComponent {
  @Input()
  address = '';
}
