import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

// This service is to provide our external partners with a common, unique and non-identifying ID to tie sessions together for troubleshooting purposes.
// This outputs a UUID similar to the following: 73906e57-fb83-4209-98c9-655d352480b3
@Injectable()
export class GuidService {
  private readonly currentGuid: string;

  constructor() {
    this.currentGuid = uuidv4();
  }

  get guid() {
    return this.currentGuid;
  }
}
