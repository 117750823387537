import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { RequestService } from './request.service';

// This service is meant to track visited pages and submited content for static pages.

const TRACK_PAGE_VIEW = 'page_view';
const TRACK_PAGE_SUBMIT = 'page_submit';

@Injectable()
export class TrackingService {
  constructor(private requestService: RequestService, private router: Router) {}

  trackPageView(): void {
    this.trackEvent(TRACK_PAGE_VIEW);
  }

  trackPageSubmit(content: any): void {
    this.trackEvent(TRACK_PAGE_SUBMIT, content);
  }

  private trackEvent(action: string, content: any = null): void {
    const payload = {
      tracking: {
        action,
        path: this.cleanPath(),
        params: content,
      },
    };
    this.requestService
      .post(`${environment.baseUrl + environment.apiPrefix}/trackings`, payload, true)
      .subscribe();
  }

  // Get the url path without the query parameters
  private cleanPath(): string {
    return this.router.url.split('?')[0];
  }
}
