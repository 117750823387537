// FYI: This is a file to store the URLs of our external scripts.

import { environment } from '../../../environments/environment';

export interface Scripts {
  name: string;
  src: string;
}

export const scriptStore: Scripts[] = [
  { name: 'address-kinterface', src: 'https://dlxiv0r5hkpxq.cloudfront.net' },
  {
    name: 'authorize-accept',
    src: environment.production
      ? 'https://js.authorize.net/v1/Accept.js'
      : 'https://jstest.authorize.net/v1/Accept.js',
  },
];
