import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RadioOptionsAttributes } from '../../models/form-elements/radio';
import { Question } from '../../models/question';
import { fieldElements } from '../../page-builder.component';
import { BaseFieldComponent } from '../base-field.component';

@Component({
  selector: 'kin-form-field',
  templateUrl: './field.component.html',
})
export class FormFieldComponent<T extends Question>
  extends BaseFieldComponent<T>
  implements AfterContentInit
{
  @Input() form: FormGroup;

  @Output() onValueChange: EventEmitter<T> = new EventEmitter<T>();

  @Output() autoSubmitRequest: EventEmitter<T> = new EventEmitter<T>();

  fullBleedInputs = [
    fieldElements.autoRadio,
    fieldElements.attachedRadio,
    fieldElements.radioCard,
    fieldElements.toggle,
    fieldElements.hidden,
    fieldElements.checkbox,
  ];

  ngAfterContentInit() {
    if (this.formControl?.value) {
      requestAnimationFrame(() => this.formService.walkChildren(this.form, this.fieldData));
    }
  }

  errorTracking(index, item) {
    return item.error;
  }

  trackByField(i, item) {
    return item.name;
  }

  onChange(field: T) {
    this.formService.walkChildren(this.form, field);
    this.updateErrors();
    this.form.updateValueAndValidity();
    this.onValueChange.emit(field);
  }

  autoSubmit(field: T): void {
    this.autoSubmitRequest.emit(field);
  }

  get isAsyncValidated(): boolean {
    return !!this.fieldData.validations?.externalApi;
  }

  get shouldShowAsyncErrorMessage(): boolean {
    return (
      this.formControl.dirty &&
      this.formControl.invalid &&
      !this.formControl.pending &&
      this.isAsyncValidated &&
      this.formControl.errors.asyncInvalid === true
    );
  }

  get shouldShowAsyncValidMessage(): boolean {
    return (
      this.formControl.dirty &&
      this.formControl.valid &&
      !this.formControl.pending &&
      this.isAsyncValidated
    );
  }

  get shouldConstrainInput(): boolean {
    if (this.fieldData.constrainWidth == null) {
      return !this.fullBleedInputs.includes(this.fieldData.type as fieldElements);
    }
    return this.fieldData.constrainWidth;
  }

  get currentlyHasNoValue(): boolean {
    const field = this.form.get(this.fieldData.name);
    const hasValue = !!field.value || field.value === 0;
    return !hasValue;
  }

  /**
   * Used to determine if any of the radio buttons have images that need to be displayed.
   * If there are images, the funnel's auto-radio cards <kin-form-radio> will be used,
   * otherwise the <ngk-form-radio> shared component will be used.
   * @param radioOptions
   * @returns boolean of whether any of the radioOptions have images
   */
  radioHasImages(radioOptions: RadioOptionsAttributes[]) {
    return radioOptions.some((opt) => opt.image);
  }
}
