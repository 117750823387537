import { Injectable } from '@angular/core';
import { scriptStore, Scripts } from '../stores/external-scripts.store';

declare let document: any;

// Adapted from https://stackoverflow.com/a/42766146
// This service is intended to allow Angular to know when a script is finished loading, and do things after such an event.
// This also allows us to have control over when a certain third-party script loads.

@Injectable()
export class ExternalScriptService {
  private scripts: { loaded?: boolean; src?: string } = {};

  constructor() {
    scriptStore.forEach((script: Scripts) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: string) {
    return new Promise((resolve) => {
      if (this.scripts[name].loaded) {
        resolve({
          script: name,
          loaded: true,
        });
      } else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({
            script: name,
            loaded: true,
          });
        };
        script.onerror = () =>
          resolve({
            script: name,
            loaded: false,
          });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
