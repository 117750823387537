import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldsetGridComponent } from './fieldset-grid.component';
import { FormAddonValidationMessagesModule } from '../form-addons/form-addon-validation-messages/form-addon-validation-messages.module';
import { FieldsetGridItemDirective } from './fieldset-grid-item/fieldset-grid-item.directive';

@NgModule({
  declarations: [FieldsetGridComponent, FieldsetGridItemDirective],
  imports: [CommonModule, FormAddonValidationMessagesModule],
  exports: [FieldsetGridComponent, FieldsetGridItemDirective],
})
export class FieldsetGridModule {}
